@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700;800&display=swap);
.Escritorio {
  height: calc(100% - 6em);
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 1em;
  padding-top: 1em;
  box-sizing: border-box;
}

.actionsButtons {
  width: 95%;
  margin: auto;
  overflow-x: scroll;
}

.actionsButtons::-webkit-scrollbar {
  height: 0px;
}

.actionsButtons button {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0.5em 1em;
  border-radius: 2em;
  white-space: nowrap;
  background: var(--color-primary-variant);
  color: var(--color-text);
  cursor: pointer;
}

.actionsButtons button i {
  margin-right: 4px;
  font-size: 1em;
}

.actionsButtons .carrusel {
  display: flex;
}

.Escritorio .mainEscritorio {
  width: 60%;
  margin-bottom: 0em;
}

.Escritorio .sidebarEscritorio {
  width: 35%;
  margin-bottom: 0em;
}

.elementEscritorio {
  width: 100%;
  background: white;
  border-radius: 10px;
  min-height: 100px;
  margin-bottom: 1em;
}

.bannerAnucio {
  /* background: black; */
  display: flex;
  color: black;
  box-sizing: border-box;
  align-items: center;
  padding: 1em 2em;
}

.bannerAnucio .info {
  width: 60%;
}

.bannerAnucio .info h3 {
  font-size: 1.4em;
  margin-bottom: 0.5em !important;
  margin-top: 0em !important;
  color: black;
}

.bannerAnucio .info p {
  font-size: 0.85em;
  margin-top: 0em;
  color: black;
}

.bannerAnucio .info button {
  border-radius: 5px;
  background: black;
  padding: 0.5em 1em;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 0.95em;
  color: white;
}

.bannerAnucio .image {
  width: 40%;
}

.bannerAnucio .image img {
  width: 100%;
  border-radius: 5px;
}

.documentosRebibidos.elementEscritorio {
  background: var(--color-primary);
}

.statusDocumentos.elementEscritorio {
  height: 40%;
}

.Escritorio::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Escritorio::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.elementEscritorio {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.elementEscritorio::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.elementEscritorio::-webkit-scrollbar {
  background: transparent;
  width: 0.3em;
}

.elementEscritorio .sectionNotification .itemsSectionNotification .item {
  width: 100%;
}
.labelElementEscritorio {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.labelElementEscritorio .count {
  display: inline-block;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  background: rgb(197, 18, 18);
  color: white;
  border-radius: 50%;
  pointer-events: none;
}

.elementEscritorio a.more {
  margin: auto;
  margin-bottom: 1em;
  text-align: center;
  width: 80px;
  display: block;
  background: black;
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 5px;
  font-size: 0.9em;
}

.statusContentMain.elementEscritorio {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0em;
  justify-content: space-around;
  flex-wrap: wrap;
  background: var(--color-primary);
}

.statusContentMain.elementEscritorio hr {
  width: 95%;
  background: #aaa;
  border: none;
  height: 0.5px;
}

.statusContentMain.elementEscritorio .statusContent {
  width: 30%;
  margin-bottom: 1em;
}

.statusContentMain.elementEscritorio .statusContent h4 {
  font-weight: bold;
  font-size: 1em;
  color: var(--color-text);
  margin: 6px 0px;
}

.moreDetails {
  display: block;
  font-size: 0.8em;
  color: var(--color-text);
  margin-top: 0.5em;
  text-decoration: underline;
}

.statusContent .content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* font-size: 0.9em; */
  color: var(--color-text);
}

.statusContent .content b {
  font-size: 1.4em !important;
  color: var(--color-text) !important;
}

.statusContent .content i {
  font-size: 2em !important;
  color: var(--color-text);
  margin-right: 0.5em;
}

.DocumentosRecibidosResumen .contentItemBodyList {
  display: flex;
  flex-wrap: wrap;
}

.DocumentosRecibidosResumen .bodyList {
  padding-bottom: 0em;
}

.DocumentosRecibidosResumen .bodyList ul .itemBodyList .contentItemBodyList li {
  margin: 0em;
}

.DocumentosRecibidosResumen {
  overflow-y: visible !important;
}

@media screen and (max-width: 1240px) {
  .Escritorio {
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    justify-content: flex-start;
    grid-gap: 5px;
    gap: 5px;
  }
  .Escritorio .mainEscritorio {
    width: 90%;
    
  }
  .Escritorio .sidebarEscritorio {
    width: 90%;
  }
  .ControllerChartsDashboard label {
    width: 33%;
  }
  .statusContentMain.elementEscritorio .statusContent .content {
    font-size: 0.8em;
    text-wrap: balance;
  }
}

@media screen and (max-width: 768px) {
  .statusContentMain.elementEscritorio .statusContent .content {
    font-size: 0.5em;
    text-wrap: balance;
  }
}

.carousel img {
  height: 50%;
}

.carousel p {
  width: 100%;
  margin-bottom: 1em;
}

.carousel a {
  text-decoration: none;
  background: black;
  color: white;
  padding: 0.8em 1em;
  border-radius: 5px;
}

.loader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loader::after {
  --color-three: var(--color-info);

  content: ' ';
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 6px solid var(--color-three);
  border-color: var(--color-three) transparent var(--color-three) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ControllerChartsDashboard{
    width: 100%;
    display: flex;
}

.ControllerChartsDashboard label{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 0px 6px;
}

.ControllerChartsDashboard label select{
    font-size: 12px;
    font-family: inherit;
    background: var(--color-primary-variant);
    border: none;
    padding: 0.5em;
    border-radius: 6px;
    margin-top: 4px;
    outline: none;
}

.periodTypeContainer{
    margin-left: auto !important;
}
.ChartDashboard{
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary-variant);
    border-radius: 16px;
    margin-top: 8px;
    color: #e7e7e7 !important;
    margin-bottom: 8px;
}
.ChartsDashboard{
    width: 100%;
    background: var(--color-primary);
    padding: 1em;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
    color: #e7e7e7 !important;
    z-index: 10000;
}



.Ingresar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
}

.Ingresar .FormLoginContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
  height: 80vh;
  box-sizing: border-box;
  margin: auto;
}

.Ingresar .FormLoginContainer .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Ingresar .FormLoginContainer .logo img {
  width: 400px;
  height: auto;
}

.Ingresar .FormLoginContainer .titleIngresar {
  font-size: 2em;
  margin-bottom: 0em;
}

.Ingresar .FormLoginContainer .Form {
  background: var(--color-primary);
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 2em;
}

.illustrationIngresar {
  width: 45%;
  position: sticky;
  top: 0;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
}

.illustrationIngresar img {
  width: 100%;
}

.Ingresar .FormLoginContainer .Form h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0;
}

.Ingresar .FormLoginContainer .Form p {
  font-size: 0.9em;
  margin-bottom: 1.5em;
}

.Ingresar .FormLoginContainer .Form label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  margin-top: 0.8em;
}

.Ingresar .FormLoginContainer .Form label .field {
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--color-primary-variant);
  border-radius: 10px;
  height: 45px;
  overflow: hidden;
  margin-top: 0.5em;
}

.Ingresar .FormLoginContainer .Form label .field input {
  width: 100%;
  background: transparent;
  height: 100%;
  border: none;
  padding: 0em 1em;
  outline: none;
  font-size: 1.1em;
}

.Ingresar .FormLoginContainer .Form label .field .options {
  height: 100%;
}

.Ingresar .FormLoginContainer .Form label .field .options button {
  border: none;
  background: var(--color-text);
  height: 100%;
  cursor: pointer;
  padding: 0.8em;
  box-sizing: border-box;
}

.Ingresar .FormLoginContainer .Form label .field .options button i {
  color: var(--color-primary);
}

.Ingresar .FormLoginContainer .Form label .field .options button i {
  pointer-events: none;
}

.Ingresar .FormLoginContainer .Form label .field .options button.desactive {
  background: var(--color-text);
}

.Ingresar
  .FormLoginContainer
  .Form
  label
  .field
  .options
  button.desactive
  i::before {
  color: var(--color-primary-variant);
  font-size: 1.5em;
}

.Ingresar .FormLoginContainer .Form label .field .options button.active {
  background: var(--color-text);
  opacity: 0.7;
}

.Ingresar .FormLoginContainer .Form label .field .options button.active i {
  color: var(--color-primary);
  font-size: 1.5em;
}

.Ingresar .FormLoginContainer .Form label .field .options button:hover svg {
  opacity: 1 !important;
}

.optionsSecondaryForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8em;
}

.optionsSecondaryForm a {
  font-size: 0.9em;
  width: auto;
  display: inline-block;
  text-align: right;
  color: var(--color-text);
}

.optionsSecondaryForm label {
  width: 50% !important;
}

.Ingresar .FormLoginContainer,
.Form .btnIngresar {
  cursor: pointer;
  margin-top: 1em;
  font-size: 1em;
  padding: 0.5em 2.5em;
  font-family: inherit;
  border: none;
  border-radius: 10px;
  outline: none;
}

.Form .btnIngresar {
  color: var(--color-primary);
  background: var(--color-text);
}

.registerLink {
  margin-top: 2em;
}

.registerLink a {
  font-weight: bold;
  color: var(--color-text);
}

.noneMargin {
  margin-top: 0.5em;
  margin-bottom: 0em !important;
}

@media screen and (max-width: 1024px) {
  .Ingresar .FormLoginContainer {
    display: flex;
    height: 100dvh;
    width: 90%;
    margin: 0 auto;
  }
  .illustrationIngresar {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .Ingresar .FormLoginContainer {
    width: 95%;
    padding: 0 10px;
  }
  .Ingresar .FormLoginContainer .logo img {
    width: 100%;
  }
  .Ingresar .FormLoginContainer .Form h4 {
    font-size: 1.2em;
  }
}

label.text {
  box-sizing: border-box;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.validCedula {
  margin: 1em 0em !important;
}

.validCedulaInput {
  width: 300px;
  font-size: 1.2em !important;
}

.validcedulaSlide .steps span {
  font-size: 1.2em;
}

.validcedulaSlide h4,
.validcedulaSlide p {
  text-align: center;
  width: 450px !important;
  margin-bottom: 0.5em !important;
}

.validcedulaSlide h4 {
  font-size: 1.8em !important;
}

.validcedulaSlide p {
  font-size: 1em !important;
}

.validcedulaSlide label {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.validcedulaSlide {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

label.text input,
label.text select,
label.text textarea {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: #eee;
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.registroForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.registroForm h4 {
  width: 100%;
}

.registroForm button {
  margin-bottom: 1em;
}

.cardData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

/* --------------- */

.Registro {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  background: white;
}

.Registro .FormLoginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0em 3em;
  box-sizing: border-box;
  margin: auto;
}

.Registro .FormLoginContainer .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Registro .FormLoginContainer .logo img {
  width: 300px;
  height: auto;
}

.Registro .FormLoginContainer .titleRegistro {
  font-size: 2em;
  margin-bottom: 0em;
}

.Registro .FormLoginContainer .Form {
  background: white;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  height: 80vh;
}

.Registro .FormLoginContainer .Form {
  box-shadow: none !important;
  min-height: 100vh;
  height: auto !important;
}

.Registro .FormLoginContainer .Form .carrusel {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.4s;
}

.Registro .FormLoginContainer .Form .slide {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2em 3em;
}

.illustrationRegistrar img {
  width: 100%;
}

.Registro .FormLoginContainer .Form h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.Registro .FormLoginContainer .Form p {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.Registro .FormLoginContainer .Form label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  margin-top: 0em;
  margin-bottom: 0em;
  padding: 1em;
  box-sizing: border-box;
}

.gridPlanes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
}

.gridPlanes .plan {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #666;
  border-radius: 1em;
  height: 80px;
}

.Registro .FormLoginContainer .Form label .field {
  width: 100%;
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 10px;
  height: 45px;
  overflow: hidden;
  margin-top: 0.5em;
}

.Registro .FormLoginContainer .Form label .field input {
  width: 100%;
  background: transparent;
  height: 100%;
  border: none;
  padding: 0em 1em;
  outline: none;
  font-size: 1.1em;
}

.Registro .FormLoginContainer .Form label .field .options {
  height: 100%;
}

.Registro .FormLoginContainer .Form label .field .options button {
  cursor: pointer;
  border: none;
  background: #ddd;
  height: 100%;
  padding: 0.8em;
  box-sizing: border-box;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  height: 1.5em;
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive {
  background: #ddd;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive svg {
  height: 1.8em;
  fill: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active {
  background: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active svg {
  height: 1.8em;
  fill: white;
}

.Registro .FormLoginContainer .Form label .field .options button:hover svg {
  opacity: 1 !important;
}

.optionsSecondaryForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8em;
}

.optionsSecondaryForm a {
  font-size: 0.9em;
  width: auto;
  display: inline-block;
  text-align: right;
  color: black;
}

.optionsSecondaryForm label {
  width: 50% !important;
}

.btnRegistro {
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0.5em 2.5em;
  font-family: inherit;
  background: black;
  border: none;
  color: white;
  border-radius: 10px;
  outline: none;
}

.btnRegistro:hover {
  background-color: #111;
}

.btnRegistro:disabled {
  cursor: not-allowed;
  background-color: #d6d6d6;
  color: #b6b6b6;
}

.cardDataInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.cardDataInput label {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.cardDataInput label input {
  width: 100%;
  box-sizing: border-box;
}

.tablePlanes {
  background: #f9f9f9;
  border-radius: 0.5em;
  overflow: hidden;
  width: 80% !important;
}

.planesStep {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.infoStep {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.infoPayStep {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

.infoStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  text-align: left;
}

.infoStep p {
  width: 500px;
  font-size: 1em !important;
  text-align: left;
}

.actionFixed {
  position: fixed;
  height: 80px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #666;
  background: white;
}

.tables {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 1em;
}

.planesStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
}

.infoPayStep p {
  width: 600px;
  font-size: 1em !important;
  align-items: left;
}

.infoPayStep .steps {
  width: 600px;
}

.infoPayStep h4 {
  width: 600px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  align-items: left;
}

.planesStep p {
  width: 500px;
  font-size: 1em !important;
}

.tablePlanes th {
  font-size: 0.8em;
}

.tablePlanes th h4 {
  text-align: center;
  width: 100%;
}

.tablePlanes td {
  font-size: 0.8em;
}

.backButton {
  outline: none;
  background: transparent;
  border: none;
  margin-right: 1em;
  transform: rotate(90deg);
  cursor: pointer;
  padding: 0;
}

.backButton svg {
  width: 1.5em;
  height: 1.5em;
}

.tablePlanes {
  table-layout: fixed;
  width: 48% !important;
  border-collapse: collapse;
  opacity: 0.4;
  cursor: pointer;
}

.tablePlanes:hover {
  opacity: 1;
}

.resumen {
  width: 45%;
  padding: 1.5em;
  box-sizing: border-box;
  background: #eee;
  min-height: 400px;
  margin-left: auto;
  border-radius: 1em;
}

.resumen h4 {
  font-weight: 500 !important;
}

.resumen ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.resumen ul li select {
  padding: 0.2em;
  background: white;
}

.tablePlanes.active {
  opacity: 1;
}

.tablePlanes td {
  width: 50%;
  padding: 0.8em;
  font-size: 0.9em;
  margin: 0em 1em;
}

.tablePlanes th {
  width: 50%;
  padding: 0.5em;
  margin: 0em 1em;
}

.tablePlanes tbody tr:nth-child(2n) {
  background: #eee;
}

.tablePlanes th.persona {
  background: rgb(84, 86, 204);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.asistido {
  background: rgb(246, 126, 56);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.juridico {
  background: rgb(207, 64, 64);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes .priceTable th span {
  text-align: center !important;
  color: white;
  font-size: 0.7em;
  font-weight: normal;
  margin: 0em;
  margin-left: 1em;
}

.priceTable th b {
  font-size: 1.4em;
  margin-bottom: 0em !important;
  font-weight: 500;
}

.priceTable .opacith {
  opacity: 0.8;
}

.btnPlanes {
  margin: auto;
  margin-top: 2em !important;
}

.navRegistro {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0.5em 1em;
  justify-content: space-between;
  border-bottom: 1px solid black;
  align-items: center;
}

.navRegistro img {
  height: 60px;
}

.navRegistro a {
  text-transform: uppercase;
  color: black;
  text-decoration: none;
}

.FormInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.paymentConfirmationModal {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 696969;
}

.paymentConfirmationModal .content h3 {
  margin-bottom: 0;
}

.paymentConfirmationModal .content {
  background-color: white;
  min-width: 30vw;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.paymentConfirmationModal .content .phrase {
  margin-block: 0.25rem;
}

.paymentConfirmationModal .content button {
  margin: 1rem 0;
}

.FormContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  right: 0%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: slideForm 200ms forwards linear;
}

@keyframes slideForm {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

.FormContainer .Form {
  background: var(--color-primary-variant);
  width: 90%;
  max-width: 1240px;
  height: 90vh;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.FormContainer .Form .FormContent {
  height: calc(100% - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.FormContainer .Form .FormContent h4 {
  font-size: 2.5em;
  color: var(--color-text);
}

.FormContainer .Form .FormTopBar h4 {
  font-size: 1.3em;
  font-weight: 500;
}

.FormTopBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0em 2em;
  background: var(--color-primary);
  border-radius: 10px;
}

.FormTopBar .FormTitle h4 {
  font-size: 1.3em;
  font-weight: 500;
}

.optionsFormTopBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.optionsFormTopBar label {
  font-size: 0.7em !important;
  margin-right: 2em;
  display: flex;
  flex-direction: column;
}

.optionsFormTopBar a {
  line-height: 0;
}

.optionsFormTopBar label input {
  margin-top: 0.3em !important;
  width: 100px;
  border-radius: 5px;
  border: none;
  padding: 0.3em 1em;
  font-size: 0.9em !important;
}

.optionsFormTopBar button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.optionsFormTopBar button svg,
.optionsFormTopBar a svg {
  width: auto;
  height: 1.8em;
  fill: var(--color-text);
  margin-left: 1em;
}

.optionsFormTopBar button i,
.optionsFormTopBar a i {
  font-size: 1.5em;
  color: var(--color-text);
  margin-left: 1em;
}

.optionsFormTopBar button.closeForm svg {
  width: auto;
  height: 1.5em;
}

.optionsFormTopBar button.closeForm i {
  font-size: 1.5em;
  color: var(--color-text);
}

.optionsFormTopBar button.closeForm {
  outline: none;
}

.BackSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.BackSection h4 {
  font-size: 1.3em;
  font-weight: 500;
}

.BackSection svg {
  transform: rotate(90deg);
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.8em;
}

.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

.workspace .sidebarForm {
  width: 25%;
  border-left: 1px solid var(--color-border);
  overflow-y: scroll;
}

.workspace .sidebarForm::-webkit-scrollbar-thumb,
.workspace .mainForm .sectionForm::-webkit-scrollbar-thumb {
  width: 0.4em;
  background: var(--color--primary);
  border-radius: 2em;
}

.workspace .sidebarForm::-webkit-scrollbar,
.workspace .mainForm .sectionForm::-webkit-scrollbar-thumb {
  width: 0.4em;
}

.workspace .mainForm {
  width: 75%;
}

.workspace .sidebarForm .actionForm {
  border-bottom: 1px solid var(--color-border);
  padding: 0.5em 1em;
  height: 50px;
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 0;
  background: var(--color-primary-variant);
}

.workspace .sidebarForm .saveForm {
  width: 45%;
  height: 35px;
  border: none;
  background: var(--color-success);
  color: white;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.9em;
  margin: 0em 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workspace .sidebarForm .generarForm i,
.workspace .sidebarForm .saveForm i {
  color: white;
  margin-right: 0.5em;
}

.workspace .sidebarForm .generarForm {
  padding: 0.8em;
  width: 45%;
  border: none;
  background: var(--color-info);
  color: white;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  font-family: inherit;
}

.buttonForm {
  margin-top: 6px;
}

.workspace .sidebarForm label.text {
  box-sizing: border-box;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.workspace .sidebarForm label.text input,
.workspace .sidebarForm label.text select,
label.text textarea {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: var(--color-primary);
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.workspace .sidebarForm label.checkbox {
  font-size: 0.8em;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.workspace .sidebarForm label.checkbox input {
  margin-left: 2em;
}

.workspace .mainForm {
  display: flex;
  align-content: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}

.workspace .mainForm label {
  box-sizing: border-box;
  margin-bottom: 0em;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.workspace .mainForm label input {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: var(--color-primary) !important;
  color: var(--color-text) !important;
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.workspace .mainForm label select {
  padding: 0.8em 1em;
  border-radius: 5px;
  background: var(--color-primary);
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  color: var(--color-text);
}

.searchForm {
  width: 100%;
  display: flex;
  align-items: center;
}

.searchForm input {
  width: 100%;
  border-radius: 5px 0px 0px 5px !important;
}

.searchForm button svg {
  width: 1.3em;
  height: 1.3em;
  fill: var(--color-primary);
}

.searchForm button {
  padding: 0.8em 0.8em;
  border-radius: 0em 5px 5px 0em;
  background: var(--color-text);
  border: none;
  margin-top: 0.5em;
  color: var(--color-primary);
}

.searchForm button i::before {
  color: var(--color-primary);
}

input:disabled {
  background: var(--color-primary) !important;
  opacity: 0.8;
  color: var(--color-border);
}

.navigationForm {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  height: 50px;
  padding: 0.5em 1em;
}

.navigationForm button {
  border-radius: 5px;
  border: none;
  padding: 0.4em;
  font-size: 0.85em;
  font-family: inherit;
  cursor: pointer;
  margin-right: 1.5em;
  background: var(--color-text);
  color: var(--color-primary);
}

.navigationForm button.active {
  background: var(--color-primary);
  font-weight: 700;
  color: var(--color-text);
}

.FormProducto .mainForm {
  padding: 0em;
}

.FormProducto .mainForm .sectionForm {
  width: 100%;
  height: calc(100% - 50px);
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em;
  padding-bottom: 1em;
  box-sizing: border-box;
  justify-content: space-between;
  align-content: flex-start;
  scroll-behavior: auto;
}

.FormProducto .mainForm .sectionForm .listSection {
  width: 100%;
  margin: auto;
}

.FormProducto .mainForm .sectionForm .listSection .emptyResultTable {
  height: 350px;
  background: var(--color-primary);
  font-size: 0.8em;
}

.titleSectionForm {
  padding-left: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleSectionForm span {
  font-size: 0.8em;
  color: var(--color-text);
}

.titleSectionForm h3 {
  font-weight: 500;
  font-size: 1.5em;
  margin: 0.5em 0em;
}

.navigationForm .searchGeneral.active {
  background: var(--color-primary);
  height: 2.5em;
}

.navigationForm .searchGeneral.active input {
  padding: 0.8em 0.2em !important;
}

.navigationForm .searchGeneral.active svg {
  fill: white;
}

.navigationForm .searchGeneral.active input {
  color: white !important;
  font-size: 0.9em;
}

.navigationForm .searchGeneral.active input::placeholder {
  color: white;
}

.cabySelecionadoList {
  width: 100%;
  padding: 0;
  background: var(--color-primary);
  border-radius: 10px;
}

.cabySelecionadoList li {
  list-style: none;
  box-sizing: border-box;
  padding: 1em;
  display: flex;
  justify-content: space-between;
}

.cabySelecionadoList li span {
  display: inline-block;
  width: 50%;
  font-size: 0.8em;
  text-align: end;
}

.cabySelecionadoList li b {
  font-size: 0.8em;
}

.cabySelecionadoList li:nth-child(2n) {
  background: var(--color-primary-variant);
}

.mainForm .pagination {
  background: var(--color-text);
  border-radius: 8px 8px 0px 0px;
  height: 70px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
  bottom: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainForm .pagination .controllers {
  display: flex;
}

.mainForm .pagination .controllers span {
  margin: 0em 1em;
  color: var(--color-primary);
}

.mainForm .listSection {
  padding: 1em 0em 1em 0em;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.previousPage {
  background: var(--color-primary);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.previousPage svg {
  width: 1em;
  fill: var(--color-text);
  transform: rotate(90deg);
}

.nextPage {
  background: var(--color-primary);
  width: 2em;
  height: 2em;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nextPage svg {
  width: 1em;
  fill: var(--color-text);
  transform: rotate(-90deg);
}

.contentItemBodyList.selected {
  background: var(--color-primary-variant) !important;
}

.contentItemBodyList.selected li {
  color: black;
}

.contentItemBodyList.selected li b {
  color: var(--color-text);
}

.contentItemBodyList.selected li button svg {
  fill: white !important;
}

.dropDownSection {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em 0.5em;
  cursor: pointer;
}

.dropDownSection.toggled button {
  transform: rotate(180deg) !important;
}

.dropDownSection span {
  background: var(--color-text);
  color: var(--color-primary);
  padding: 0.5em 1em;
  border-radius: 2em;
  pointer-events: none;
}

.dropDownSection hr {
  height: 1px;
  width: 70%;
  border: none;
  background: var(--color-border);
  pointer-events: none;
}

.dropDownSection button {
  background: var(--color-text);
  border: none;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.dropDownSection button i::before {
  font-size: 1em;
  color: var(--color-primary);
}

.actionsSection {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.actionsSection button {
  margin-left: 0;
  margin-right: 1em;
}

.actionsSection .searchGeneral {
  margin-right: 1em !important;
  background: var(--color-primary);
}

.dropDownItem {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btnForm {
  background: var(--color-text);
  color: var(--color-primary);
  align-self: flex-end;
  margin-bottom: 0.8em;
  padding: 0.8em 0.5em;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}

.btnForm:hover {
  opacity: 0.8;
}

.btnForm i::before {
  color: var(--color-primary);
  font-size: 1em;
  margin-right: 0.5em;
}

.btnForm svg {
  width: 18px;
}

.css-1fhf3k1-control {
  border-color: transparent !important;
}

.inputEditable {
  margin-left: 0em !important;
  margin-top: 0.5em !important;
  padding: 0.8em 0em !important;
  height: 3em !important;
  background: var(--color-primary) !important;
}

.inputEditable input {
  width: 100% !important;
  padding: 0.8em 0.5em !important;
  margin-top: 0em !important;
}

.inputEditable .optionsSearchGeneral button {
  padding: 0.5em !important;
  background: var(--color-primary);
}

.totalesEmpty {
  width: 100%;
  background: var(--color-primary);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1em;
  margin-top: 1em;
}

.totalesEmpty h4 {
  font-size: 1.1em !important;
  text-align: center;
  color: var(--color-text) !important;
  font-weight: 500;
}

.totalesEmpty button {
  background: var(--color-text) !important;
  color: var(--color-primary) !important;
  font-family: inherit;
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
}

.statusReader:disabled {
  background: var(--color-success) !important;
  color: white;
}

.statusGenerado:disabled {
  background: var(--color-info) !important;
  color: white;
}

.statusAnulado:disabled {
  background: var(--color-error) !important;
  color: white;
}

.css-14jk2my-container {
  margin-top: 0.5em;
  background: var(--color--primary) !important;
  border-radius: 5px;
}

.css-1fhf3k1-control {
  background: var(--color-primary) !important;
}

.css-26l3qy-menu {
  background: var(--color--primary) !important;
  opacity: 1;
}

.css-2b097c-container {
  background: var(--color-primary);
  color: var(--color-text) !important;
}

.css-1n7v3ny-option {
  background: var(--color-text) !important;
  color: var(--color-primary) !important;
}

.css-26l3qy-menu {
  background: var(--color-primary) !important;
}

.css-2b097c-container::placeholder {
  color: var(--color-text) !important;
}

.css-tlfecz-indicatorContainer {
  color: var(--color-text) !important;
}

.css-1pahdxg-control {
  background: var(--color--primary) !important;
  color: var(--color-text) !important;
}

.css-gg45go-NoOptionsMessage {
  color: var(--color-text) !important;
}

.css-1rhbuit-multiValue {
  background: var(--color-primary-variant) !important;
  color: var(--color-text) !important;
}

.css-12jo7m5,
.css-1gtu0rj-indicatorContainer {
  color: var(--color-text) !important;
}

.anularAction {
  background: var(--color-error) !important;
  border-bottom: 7px solid var(--color-error) !important;
}

.renviarAction {
  background: var(--color-info) !important;
  border-bottom: 7px solid var(--color-info) !important;
}

.navigationForm .setOptions span {
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 0.8rem;
}

.navigationForm .setOptions span:hover {
  color: var(--color-text);
}

.navigationForm .setOptions span svg {
  height: 1.5em;
  width: auto;
  fill: var(--color-text);
}

.navigationForm .setOptions {
  display: flex;
  margin-left: auto;
}

.navigationForm .setOptions a {
  font-size: 0.7em;
  display: inline-flex;
  margin-right: 1em;
  flex-direction: column;
  background: transparent;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  text-decoration: none;
}

@media screen and (max-width: 874px) {
  .FormContainer .Form {
    width: 95%;
  }
  .list-noscroll {
    overflow-x: hidden !important;
  }
  .FormProducto .mainForm .sectionForm {
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .btnForm {
    width: 100% !important;
  }
  .workspace .mainForm label {
    padding: 4px;
    width: 95% !important;
  }
  .workspace .mainForm .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
  }
  .workspace .mainForm {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .FormProducto .mainForm .sectionForm label {
    width: 90% !important;
    padding: 0;
  }
  .FormProducto .mainForm .sectionForm .listSection {
    min-height: 150px !important;
    height: max-content !important;
    margin: 0;
    overflow: scroll;
  }
  .listSection .headList ul {
    /* overflow-x: scroll; */
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 25px;
    gap: 25px;
  }
  .cabySelecionadoList {
    font-size: 11px;
  }
  .titleSectionForm {
    font-size: 12px;
  }
  .workspace .mainForm {
    width: 65%;
  }
  .workspace .sidebarForm {
    width: 35%;
  }
  .workspace .sidebarForm .saveForm {
    width: 100%;
  }
  .navigationForm button {
    margin-right: 0;
  }
  .searchGeneral {
    width: 100% !important;
  }
  .searchGeneral .optionsSearchGeneral {
    font-size: 12px;
    text-wrap: nowrap;
  }
  .navigationForm {
    flex-wrap: wrap;
    height: max-content;
    grid-gap: 4px;
    gap: 4px;
    padding: 0.2em;
    justify-content: space-around;
  }
  .workspace .sidebarForm .generarForm {
    width: 100%;
  }
  .navigationForm .searchGeneral.active {
    display: flex;
    flex-direction: row;
    grid-gap: 2px;
    gap: 2px;
  }
  .navigationForm .searchGeneral.active input {
    font-size: 12px;
  }
  .workspace .sidebarForm .generarForm .buttonForm {
    display: flex;
    margin-top: 0;
    flex-direction: row;
  }
  .navigationForm button {
    font-size: 12px;
  }
}

@media screen and (max-width: 574px) {
  .workspace .mainForm {
    width: 60%;
  }
  .workspace .sidebarForm {
    width: 40%;
  }
  .workspace .sidebarForm label.text {
    font-size: 0.6em;
  }
  .titleSectionForm,
  .actionsSection {
    font-size: 11px;
  }
  .workspace .mainForm label {
    font-size: .7em;
  }
}

.Productos {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 120px;
}

.Productos::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Productos::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}
.TopBar {
  height: 70px;
  background: var(--color-primary);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 0.5em 1em;
}

.checkNotification {
  background: var(--color-text) !important;
  color: var(--color-primary) !important;
  border-radius: 50%;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0em;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  cursor: pointer;
}

.checkNotification:hover {
  color: white !important;
  background: var(--color-info) !important;
}

.helpAccess {
  position: fixed;
  bottom: 1em;
  right: 1em;
  background: var(--color-text) !important;
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helpAccessContainer {
  position: fixed;
  right: 1em;
  bottom: 100px;
  width: 250px;
  border-radius: 10px;
  padding: 1em;
  box-sizing: border-box;
  background: var(--color-text) !important;
  transition: all 0.3s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.helpAccessContainer h4 {
  margin: 0;
  color: var(--color-primary);
}

.helpAccessContainer ul {
  padding-left: 0;
}

.helpAccessContainer ul li {
  list-style: none;
}

.helpAccessContainer ul li a {
  font-size: 0.9em;
  text-decoration: none;
  color: var(--color-primary);
}

.helpAccessContainer ul li a:hover {
  font-weight: 500;
}

.helpAccess span {
  display: none;
}

.helpAccess.close span {
  display: block;
  font-size: 2em;
  color: var(--color-primary);
}

.helpAccess.close svg {
  display: none;
}

.helpAccess svg {
  fill: var(--color-primary) !important;
  width: 1em !important;
  height: 1em !important;
}

.TopBar .titleSection {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.2em;
  color: var(--color-text);
}

.TopBar .optionsTopBar {
  display: flex;
  align-items: center;
}

.searchGeneral {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 1em;
  background: var(--color-primary-variant);
  border-radius: 5px;
  overflow: hidden;
  align-items: center;
  height: 2.3em;
  padding: 0em 0.5em;
  box-sizing: border-box;
}

.searchGeneral input {
  border: 0;
  padding: 0.5em;
  background: transparent;
  width: 70px;
  font-size: 0.9em;
  outline: none;
  pointer-events: none;
  color: var(--color-text);
}

.searchGeneral input::placeholder {
  color: var(--color-text);
}

.searchGeneral .optionsSearchGeneral {
  height: 70%;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.searchGeneral .optionsSearchGeneral button {
  border: 0;
  background: transparent;
  padding: 0.5em;
  pointer-events: none;
}

.searchGeneral .optionsSearchGeneral span {
  font-size: 0.7em;
  height: 100%;
  box-sizing: border-box;
  padding: 0em 0.3em;
  color: var(--color-text);
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-primary);
  border-radius: 5px;
  pointer-events: none;
}

.btnNotifications {
  position: relative;
}

.btnNotifications .numberNotification {
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  font-weight: bold;
  background: rgb(197, 18, 18);
  color: white;
  border-radius: 50%;
  pointer-events: none;
}

.TopBar .optionsTopBar .notifications button svg,
.TopBar .optionsTopBar .userMenu button svg,
.TopBar .optionsTopBar .createContentShortcut button svg {
  width: 2.2em;
  height: 2.2em;
  pointer-events: none;
  fill: var(--color-text);
}

.TopBar .optionsTopBar .userMenu button,
.TopBar .optionsTopBar .createContentShortcut button {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
}

.chevronSubmenu {
  width: 1em !important;
  height: 1em !important;
  margin-left: 0.3em;
}

.searchGeneral .optionsSearchGeneral button svg {
  width: 1.4em;
  height: 1.4em;
  fill: var(--color-text);
}

.searchGeneral .optionsSearchGeneral button i {
  font-size: 1.2em;
}

.TopBar button {
  border: 0;
  background: transparent;
  padding: 0.5em;
  outline: none;
  cursor: pointer;
}

.TopBar button i {
  font-size: 2em;
}

.TopBar button.clearSearch i {
  font-size: 1.5em;
}

.TopBar button.clearSearch {
  padding: 0;
}

@keyframes slideLeft {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.keyView {
  position: fixed;
  top: 50%;
  right: 50%;
  width: 500px;
  height: max-content;
  background: var(--color-primary);
  transform: translate(50%, -50%);
  box-shadow: 0 8px 8px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 1em;
  animation: slideLeft 0.2s;
  z-index: 800;
}

.keyView.toggled {
  animation: slideRight 0.2s linear forwards;
  z-index: -1;
}

.keyview-content {
  width: 90%;
  margin: auto;
}

.keyView textarea {
  height: 100px;
  width: 100%;
  background: #eee;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 1em;
  box-sizing: border-box;
  font-family: inherit;
}

.keyView h3 {
  color: var(--color-text);
  font-size: 1em;
  margin-top: 0.2em;
  margin-bottom: 0.8em;
  display: flex;
}

.keyView .confirmButton {
  background: #222;
  font-size: 1em;
  color: white;
  padding: 0.5em 1em;
  width: 200px !important;
  border-bottom: 6px solid black;
  margin-top: 0em;
}

.keyView .closeButton {
  width: 30px;
  height: 30px;
  background: transparent;
  padding: 0;
  margin: 0;
  margin-left: auto;
  display: grid;
  place-items: center;
}

.keyView .closeButton svg {
  cursor: pointer;
  margin: auto;
  width: 20px;
  height: 20px;
  fill: var(--color-text);
}

.keyView ul {
  padding: 0;
  margin: 0;
  margin-bottom: 1em;
}

.keyView li {
  list-style: none;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  color: #666;
  font-weight: bold;
}

.keyView li span {
  font-weight: normal;
}

.keyView button,
.keyView a {
  cursor: pointer;
  width: 100%;
  border: none;
  font-size: 1em;
  font-weight: 500;
  border-radius: 10px;
  background-color: var(--color-success);
  padding: 1em;
  margin-top: 1em;
  color: var(--color-text);
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms linear;
}

.keyView button:hover {
  background-color: #222;
}

.keyView button:hover > svg {
  color: #fff;
  fill: #fff;
}

.keyView button svg,
.keyView a svg {
  width: 1.5em;
  height: 1.5em;
  fill: white;
  margin-right: 1em;
}

.overloadUser,
.overloadShortcut,
.overloadNofications {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.05);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.modalNofications {
  display: block;
  position: fixed;
  top: 70px;
  right: 0em;
  height: calc(100vh - 70px);
  width: 400px;
  background: var(--color-primary);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
  z-index: 10000;
  box-sizing: border-box;
}

.modalNofications h4,
.modalFilters h4 {
  color: #888;
}

.modalNofications.toggled,
.modalFilters.toggled,
.modalShortcut.toggled,
.userModal.toggled,
.Notification.toggled,
.alertContainer.toggled,
.helpAccessContainer.toggled {
  right: -100%;
}

.overloadUser.hidden,
.overloadShortcut.hidden,
.overloadNofications.hidden {
  display: none;
}

.modalFilters {
  position: fixed;
  top: 100px;
  right: 1em;
  width: calc(80% - 2em);
  height: 200px;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
}

.userModal {
  position: fixed;
  top: 70px;
  right: 0em;
  width: 300px;
  height: calc(100vh - 70px);
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 10000;
}

.userModal .present {
  display: flex;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid #aaa;
}

.userModal .present .avatar i {
  font-size: 2em;
}

.userModal .present .infoUser {
  width: 100%;
  margin-left: 1em;
}

.userModal .present .infoUser h4 {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0em;
  width: 100%;
  color: var(--color-text);
}

.userModal .present .infoUser p {
  font-size: 0.8em;
  color: var(--color-text);
  margin: 0;
}

.userModal ul {
  padding: 0em 1em;
  box-sizing: border-box;
}

.userModal ul li {
  list-style: none;
  margin: 0;
}

.userModal ul li a {
  text-decoration: none;
  color: var(--color-text);
  display: inline-block;
  width: 100%;
  padding: 0.3em 1em;
}

.userModal ul li button {
  background: transparent;
  display: flex;
  justify-content: space-between;
  font-size: 1em !important;
  font-family: inherit;
  width: 100%;
  padding: 0.3em 1em;
  color: white;
  background: #222;
  border-radius: 10px;
  text-align: left;
}

.userModal ul li button svg {
  width: 1.5em;
  height: 1.5em;
  fill: white;
}

.userModal ul li button i {
  font-size: 1em;
  color: var(--color-text);
}

.modalShortcut {
  position: fixed;
  top: 70px;
  right: 0em;
  height: calc(100vh - 70px);
  width: 350px;
  background: var(--color-primary);
  display: flex;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  overflow-y: scroll;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 10000;
}

.Notification {
  position: fixed;
  top: 100px;
  right: 1em;
  min-height: 100px;
  width: 400px;
  background: white;
  border-radius: 10px;
  display: flex;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 1em;
  cursor: pointer;
  z-index: 100000;
}

.Notification .contentNotification {
  width: 90%;
  pointer-events: none;
}

.Notification .contentNotification h4 {
  font-size: 0.9em;
  margin: auto;
  pointer-events: none;
}

.Notification .contentNotification p {
  font-size: 0.7em;
  pointer-events: none;
}

.Notification button {
  width: 10%;
}

.Notification button svg {
  width: 1em;
  height: 1em;
  fill: #222;
  pointer-events: none;
}

.modalShortcut h4 {
  padding: 0.5em 1em;
  margin: 0;
  margin-bottom: 0.5em;
  color: var(--color-text);
}

.modalShortcut ul {
  width: 100%;
  padding: 0;
  margin: 0;
  /* border-top: 1px solid #777; */
}

.modalShortcut ul li {
  list-style: none;
  padding: 0em;
  margin: 0;
  width: 100%;
  /* border-bottom: 1px solid #777; */
  transition: all 0.3s;
}

.modalShortcut ul li a span {
  margin-left: 0.5em;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.5em;
  font-size: 0.85em;
  background: var(--color-primary-variant);
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.05);
}

.modalShortcut ul li a {
  width: 100%;
  display: inline-block;
  color: var(--color-text);
  text-decoration: none;
  height: 100%;
  transition: all 0.3s;
  font-family: inherit;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7em;
  padding: 0.8em 1em;
}

.modalShortcut ul li:hover {
  background: var(--color-primary-variant) !important;
}

.modalShortcut ul li:hover a {
  font-weight: bold;
  color: white;
}

.modalShortcut ul li:hover span {
  color: #222;
  background: white !important;
}

.createContentShortcut .chevronSubmenu,
.userMenu .chevronSubmenu {
  transform: rotate(360deg);
}

.createContentShortcut.toggled .chevronSubmenu,
.userMenu.toggled .chevronSubmenu {
  transform: rotate(180deg) !important;
}

.alertContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
}

.alertContainer .Alert {
  background: var(--color-primary);
  width: 700px;
  height: 300px;
  box-sizing: border-box;
  padding: 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.alertContainer .Alert h3 {
  font-size: 1.8em;
  margin: 0;
  color: var(--color-text);
}

.alertContainer .Alert .buttonsAlert {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.alertContainer .Alert .buttonsAlert button {
  padding: 1em 3em;
  font-size: 1em;
  border-radius: 10px;
}

.alertContainer .Alert .buttonsAlert button:first-child {
  background: #eee;
  color: #222;
}

.alertContainer .Alert .buttonsAlert button:last-child {
  background: #222;
  color: white;
  font-weight: bold;
}

.Search {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
}

.Search .modalSearch {
  background: var(--color-primary);
  box-sizing: border-box;
  width: 56%;
  height: calc(100vh - 80px);
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.Search .modalSearch .searchInput {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border-radius: 10px;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 0.5em 1em;
  box-sizing: border-box;
  border: 1px solid #222;
}

.Search .modalSearch .searchInput svg {
  height: 2em;
}

.Search .modalSearch .searchInput input {
  height: 100%;
  border: none;
  font-size: 1.3em;
  width: 100%;
  padding-left: 0.5em;
  outline: none;
  color: #000;
}

.Search .modalSearch .searchInput input::placeholder {
  color: #000;
}

.Search .modalSearch .searchInput i::before {
  color: #000;
}

.Search .modalSearch .searchInput .clearSearch svg {
  height: 1.2em;
  margin-right: 1em;
}

.Search .modalSearch .searchInput .filterSearch svg {
  height: 1.6em;
}

.Search .modalSearch .commands {
  width: 100%;
  background: white;
  display: flex;
  /* margin-top: auto; */
  border-radius: 10px 10px 0px 0px;
  box-shadow: -4px -3px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5em 1em;
  box-sizing: border-box;
}

.Search .modalSearch .commands .keyContainer {
  font-size: 0.8em;
  margin-right: 1.5em;
}

.Search .modalSearch .commands .keyContainer .key {
  margin-right: 0.5em;
  background: #eee;
  border-bottom: 3px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.3em 0.6em;
  color: #333;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
}

.Search .modalSearch .commands .keyContainer {
  color: #777;
}

.resultSearch {
  margin: auto;
  height: 100% !important;
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: -4px -3px 8px rgb(0 0 0 / 10%);
  align-items: center;
  font-size: 0.9em;
  color: #777;
  height: 300px;
}

.Search.toggled {
  right: -100%;
}

.emptyNotifications {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  box-sizing: border-box;
  padding: 2em;
}

.emptyNotifications i {
  color: var(--color-text);
  font-size: 5em;
}

.emptyNotifications img {
  width: 70%;
}

.emptyNotifications h4 {
  margin: 0em;
  font-size: 1.1em;
  color: var(--color-text);
}

.emptyNotifications p {
  font-size: 0.9em;
  text-align: center;
  color: var(--color-text);
}

.sectionNotification {
  box-sizing: border-box;
  padding: 1em;
  width: 100%;
}

.sectionNotification h4 {
  margin: 0;
  margin-bottom: 0.8em;
  font-weight: 500;
  color: var(--color-text);
  font-size: 0.9em;
  display: flex;
  justify-content: space-between;
}

.sectionNotification h4 .allNotifications {
  color: var(--color-text);
  font-size: 0.8em;
  display: inline-block;
  margin-left: auto;
}

.sectionNotification .itemsSectionNotification {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1em;
}

.sectionNotification .itemsSectionNotification .item {
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 10px;
  width: 95%;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  background: var(--color-primary-variant);
  position: relative;
  text-decoration: none !important;
}

.sectionNotification .itemsSectionNotification .item:hover {
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.3) !important;
}

.sectionNotification .itemsSectionNotification .item .info {
  font-size: 0.75em;
  width: 70%;
  color: var(--color-text);
}

.sectionNotification .itemsSectionNotification .item b {
  color: var(--color-text);
  width: 80%;
}

.sectionNotification .itemsSectionNotification .item .date {
  font-size: 0.7em;
  margin-left: auto;
  color: var(--color-text);
}

.sectionNotification .itemsSectionNotification .item .status {
  font-size: 0.75em;
  margin: 0;
  margin-left: auto;
  color: #f59323;
}

.readerPDF {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background: #eee;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 500;
  transition: all 0.5s !important;
}

.react-pdf__Page__canvas {
  border-radius: 10px;
}

.navReaderPDF {
  width: 100%;
  background: white;
  height: 70px;
  margin-bottom: auto;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1em;
}

.readerPDF {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  background: var(--color-primary-variant);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100000;
  transition: all 0.5s !important;
}

.react-pdf__Page__canvas {
  border-radius: 10px;
}

.navReaderPDF {
  width: 100%;
  background: var(--color-primary);
  height: 70px;
  margin-bottom: auto;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1em;
}

.viewContainer {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  position: relative !important;
}

.viewContainer .pagination {
  position: absolute;
  background: var(--color-text);
  color: var(--color-primary);
  bottom: 0;
  left: 55%;
  box-sizing: border-box;
  padding: 0.5em 1em;
  border-radius: 5px 5px 0px 0px;
}

.viewContainer .pagesSidebar {
  width: 15%;
  height: 100%;
  background: white;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
}

.viewContainer .pagesSidebar p {
  color: black;
  margin: 0;
}

.pagesSidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.pagesSidebar .react-pdf__Document {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.pagesSidebar .react-pdf__Document .react-pdf__Page__canvas {
  width: 100%;
}

.pagesSidebar .react-pdf__Document .react-pdf__Page {
  padding-top: 1em;
  padding-bottom: 1em;
}

.pagesSidebar .react-pdf__Document .react-pdf__Page__canvas {
  width: 60% !important;
  height: auto !important;
  border: 2px solid black;
  overflow: hidden;
}

.pagesSidebar .react-pdf__Document {
  overflow: hidden;
}

.react-pdf__Document * {
  color: transparent !important;
}

.react-pdf__Document {
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
}

.react-pdf__Page {
  display: flex;
  width: 100%;
  padding-top: 2em;
  padding-bottom: 4em;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
}

.readerPDF i {
  font-size: 1.5em !important;
  color: #050505 !important;
}

.closeReader {
  margin-right: 1em;
  background: transparent;
}

.downloadReader {
  display: flex;
  align-items: center;
  color: white;
  margin-left: 1em;
}

.readerPDF.toggled {
  right: -100%;
}

.titleReader {
  font-size: 0.8em;
  font-weight: 500;
  margin-right: auto;
  color: var(--color-text);
}

.readerPDF.toggled .pagination {
  display: none;
}

.react-pdf__Page__canvas {
  height: auto !important;
}

.react-pdf__Page__canvas {
  margin: auto;
}

.z-ajust .react-pdf__Page__canvas {
  width: auto !important;
  height: 85vh !important;
}

.z-50 .react-pdf__Page__canvas {
  width: 50% !important;
}

.z-75 .react-pdf__Page__canvas {
  width: 75% !important;
}

.z-100 .react-pdf__Page__canvas {
  width: 100% !important;
}

.z-125 .react-pdf__Page__canvas {
  width: 125% !important;
}

.z-150 .react-pdf__Page__canvas {
  width: 150% !important;
}

.z-175 .react-pdf__Page__canvas {
  width: 175% !important;
}

.z-200 .react-pdf__Page__canvas {
  width: 200% !important;
}

.setActions {
  align-items: center;
  display: flex;
  margin-right: 1em;
  right: 0;
}

.setActions button,
.setActions a {
  background: white !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin: 0em 0.5em;
  border-radius: 50%;
  cursor: pointer !important;
  border: none;
  width: 40px;
  height: 40px;
}

.setActions button i,
.setActions a i {
  margin-right: 0;
  font-size: 1.8em !important;
}

.viewContainer .pagination.before {
  left: 17%;
  top: 55%;
  width: 24px;
  height: 24px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  cursor: pointer;
  color: #050505 !important;
  background: transparent;
}

.viewContainer .pagination.next {
  right: 2%;
  left: auto;
  top: 55%;
  width: 24px;
  height: 24px;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  cursor: pointer;
  color: #050505 !important;
  background: transparent;
}

.setActions .cancelDoc i::before {
  color: #e23b3b !important;
}

.setActions .checkedReader i::before {
  color: #0dac22 !important;
}

.setActions .resendDoc i {
  color: #4661f6 !important;
}

.setActions .downloadReader i::before {
  color: #050505 !important;
}

.setActions .keyReader i::before {
  color: #050505 !important;
}

.resultadosPorSecciones {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 1em 2em;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.resultadosPorSecciones .seccionBusquedad {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.resultadosPorSecciones .seccionBusquedad h4 {
  font-weight: 500;
  width: 100%;
}

.seccionBusquedad .itemDeSeccion {
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  margin-bottom: 0.5em;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: all 0.3s;
}

.seccionBusquedad .itemDeSeccion:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.seccionBusquedad .itemDeSeccion h5 {
  margin: 0;
  color: #000;
}

.seccionBusquedad .itemDeSeccion span {
  font-size: 0.8em;
  color: #888;
}

.seccionBusquedad .itemDeSeccion svg {
  height: 2.5em;
  width: auto;
  margin: 0;
  fill: #555;
}

.seccionBusquedad .itemDeSeccion .iconItem {
  margin: 0;
  margin-right: 1em;
}

.seccionBusquedad .itemDeSeccion .iconItem i::before {
  color: #000 !important;
}

.seccionBusquedad .itemDeSeccion .info {
  margin-right: 1em;
}

.seccionBusquedad .itemDeSeccion .enterButton {
  margin-left: auto;
  display: none;
}

.seccionBusquedad .itemDeSeccion:hover .enterButton {
  display: block;
}

.seccionBusquedad .itemDeSeccion:hover .enterButton svg {
  height: 2.5em;
  width: auto;
}

.aceptadoStatus {
  margin-left: 1em;
  display: flex;
  background: #7ec06a;
  color: white !important;
  padding: 0.5em;
  border-radius: 5px;
}

.pendienteStatus {
  margin-left: 1em;
  display: flex;
  background: #e74e4e;
  color: white !important;
  padding: 0.5em;
  border-radius: 5px;
}

.seccionBusquedad .metadata {
  display: flex;
}

.seccionBusquedad .metadata + .enterButton {
  margin-left: 1em;
}

.seccionBusquedad .metadata {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.priceFacturaSearch {
  margin-right: 1em;
  display: inline-block;
  font-weight: bold;
  color: black !important;
}

.labelSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.labelSection hr {
  height: 1px;
  border: none;
  background: black;
  flex-grow: 3;
  pointer-events: none;
}

.labelSection button {
  background: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  margin-left: 1em;
  pointer-events: none;
}

.labelSection.toggled button {
  transform: rotate(180deg);
}

.labelSection button svg {
  width: 20px;
  height: 20px;
  fill: white;
}

.labelSection h4 {
  width: auto !important;
  margin-right: 1em;
  display: inline-flex;
  pointer-events: none;
}

.resultsSeccionBusquedad {
  width: 100%;
}

.capsulas {
  margin-bottom: 1em;
  margin-top: 1em;
  width: 90%;
}

.capsulas::-webkit-scrollbar {
  height: 0px;
}

.capsulas .carrusel {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.capsulas .carrusel button {
  margin-right: 1em;
  margin-bottom: 1em;
  font-family: inherit;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2em;
  padding: 0.5em 1em;
}

.capsulas .carrusel button.active {
  background: black;
  color: white;
}

.emptyResult {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  background: white;
  height: 100%;
  flex-direction: column;
}

.labelSection button i {
  font-size: 20px;
  color: white;
}

.emptyResult h4 {
  color: black;
  font-size: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.emptyResult button {
  font-size: 1em;
  font-family: inherit;
  background: black;
  color: white;
  padding: 0.5em 1em;
  border-radius: 5px;
}

.emptyResult img {
  width: 50%;
  height: auto;
}

.keyView .metadata li {
  font-size: 0.7em;
  color: black;
}

.keyView label {
  width: 100% !important;
  font-size: 0.9em;
  margin-bottom: 1em;
}

.keyView label select {
  width: 100%;
  margin-top: 0.5em;
  background: var(--color-primary-variant) !important;
  color: var(--color-text);
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  font-family: inherit;
  font-size: 0.9em;
  outline: none;
}

.userMenu .transfer {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  background: #b52b21;
  border-radius: 50%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.userMenu {
  position: relative;
}

.userMenu .transfer svg {
  fill: white;
  width: 0.8em;
  height: 0.8em;
}

.transferChange {
  background: #b52b21 !important;
}

.keyView label {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 0.9em;
}

.keyView label input {
  margin-top: 0.5em;
  padding: 0.5em 1em !important;
  font-family: inherit;
  border: none;
  border-radius: 5px;
  color: var(--color-text);
  font-size: 1em;
  background: var(--color-primary-variant) !important;
  outline: none !important;
}

.keyView .searchGeneral {
  width: 100% !important;
  margin-bottom: 1em !important;
  margin-left: 0;
}

.keyView .searchGeneral .optionsSearchGeneral button {
  margin-top: 0em;
}

.keyView .searchGeneral .optionsSearchGeneral button svg {
  fill: black;
}

.zoomContainer {
  margin: auto;
  display: flex;
  align-items: center;
}

.zoomContainer .numberPages {
  margin-right: 1em;
  color: #050505;
}

.zoomContainer .ajustDocument {
  margin-left: 1em;
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: 1em;
  font-size: 0.8em;
  border: none;
}

.zoomContainer .zoomController {
  border-right: 1px solid #aaaaaa;
  border-left: 1px solid #aaaaaa;
  cursor: pointer;
  color: #050505;
}

.zoomContainer .zoomController button {
  background: transparent;
  margin: 0em 0.5em;
  cursor: pointer;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 0.8em;
  border: none;
}

.zoomContainer .zoomController button:hover,
.zoomContainer .ajustDocument:hover {
  background: var(--color-primary-variant);
}

@media screen and (max-width: 1024px) {
  .TopBar {
    padding: 0 0.4em;
  }
  .titleReader {
    font-size: 1em;
  }
  .searchGeneral {
    margin-left: 0.2em;
  }
  .Search .modalSearch {
    width: 90%;
  }
}
@media screen and (max-width: 874px) {
  .titleReader {
    font-size: 10px !important;
  }
  .navReaderPDF {
    height: max-content;
  }
  .zoomContainer .zoomController {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .zoomContainer {
    font-size: 12px !important;
    margin: 0;
  }
  .setActions {
    width: 40%;
    margin: 0;
  }
  .setActions a {
    width: 35px;
    margin: 4px;
    height: 40px;
  }
  .setActions button {
    width: 35px;
    margin: 4px;
    height: 40px;
  }
}

.sectionForm {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 5rem;
}

.formCompaniaDetalles {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.formComprobantes {
  padding: 1rem 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.detalles > * {
  width: 100%;
}

.span2 {
  grid-column: span 2;
}

.span3 {
  grid-column: span 3;
}

@media screen and (max-width: 874px) {
  .formCompaniaDetalles {
    display: flex;
    flex-direction: column;
  }
  .formCompaniaDetalles label {
    padding: 0;
  }
}

.Sucursales {
    padding-bottom: 60px;
}
.Cajas {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.Cajas::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Cajas::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}
input[type="number"]::placeholder {
  color: #ccc;
}
.Cabys {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 65px;
}

.Cabys::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Cabys::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.Consecutivos {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.Consecutivos::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Consecutivos::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.Sucursales {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 65px;
}

.Sucursales::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Sucursales::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.Usuarios {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}
input[type='text']::placeholder {
  color: #ccc;
}
.Usuarios::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Usuarios::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}
.containerMain {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inner-div {
  flex-basis: 49%;
}

.containerMain {
  margin: 20px;
}
.keyViewUser {
  width: 50%;
}
.textForm {
}
.react-datepicker__navigation {
  align-items: center !important;
  background: none;
  display: flex !important;
  justify-content: center !important;
  text-align: center;
  cursor: pointer !important;
  position: absolute;
  top: 2px;
  padding: 0 !important;
  border: none !important;
  z-index: 1;
  height: 32px;
  width: 32px !important;
  text-indent: -999em;
  overflow: hidden;
  border-radius: 0px !important;
  background-color: transparent !important;
}
.react-datepicker-wrapper input {
  width: 100% !important;
}

@media screen and (max-width: 1024px) {
  .keyViewUser {
      width: 90%;
      height: max-content;
  }
  .containerMain {
      flex-direction: column;
      margin: 5px;
      overflow-y: auto;
  }
  .inner-div {
      display: flex;
      flex-direction: column;
  }
  .inner-div label {
      margin: 0;
      padding: 4px !important;
  }
}
.ActividadesEconomicas {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.ActividadesEconomicas::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.ActividadesEconomicas::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}



.Personas {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 60px;
}

.Personas::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Personas::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.Resumen .bodyList{
    padding-bottom: 2em;
}


.DocumentosRecibidos {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 70px;
}

.DocumentosRecibidos::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.DocumentosRecibidos::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.keyBtnTable {
  color: black;
  text-decoration: underline;
  padding: 0.5em 0em !important;
  border-radius: 2em;
  font-family: inherit;
  font-size: 1em;
}

.modal-download {
  width: 500px;
  position: fixed;
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  color: #f7f7f7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.title-download {
  color: #f7f7f7;
}
.buttons-download {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-around;
}

.button-download {
  cursor: pointer;
  color: #f7f7f7;
  width: 150px;
  height: 35px;
  background-color: #4b4b4b;
  display: grid;
  place-items: center;
  font-size: 18px;
  border-radius: 8px;
}
.Migracion{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Migracion .loadFile{
    background: var(--color-primary);
    width: 90%;
    height: 60vh;
    margin: auto;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Migracion .loadFile i{
    font-size: 5em;
}

.Migracion .loadFile h3{
    font-size: 2em;
    margin-top: 0em;
    font-weight: 400;
}

.Migracion .loadFile button{
    background: var(--color-text);
    color: var(--color-primary);
    padding: 0.5em 1em;
    border-radius: 2em;
    font-family: inherit;
    font-size: 1.2em;
}





















.indexConf {
    width: 20%;
    border-left: 1px solid var(--color-border);
    height: 90%;
}
  
.indexConf ul {
    padding-left: 0.8em;
}
  
.indexConf ul li {
    list-style: none;
    margin-bottom: 1em;
}
  
.indexConf ul li a {
    text-decoration: none;
    color: var(--color-text);
    font-size: 0.9em;
}
.formatsGroup{
    width: 100%;
    padding: 1.5em;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5em;
}

.formatsGroup .formatOption{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 1em 0em 0em 0em;
    border: 2px solid var(--color-text);
    border-radius: 0.5em;
    cursor: pointer;
    position: relative;
    opacity: 0.6;
}

.formatsGroup .formatOption .checkSymbol{
    position: absolute;
    width: 30px;
    height: 30px;
    font-size: 30px;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    top: -15px;
    right: -15px;
    background: var(--color-text);
}

.formatsGroup .formatOption.activeFormat, .formatsGroup .formatOption:hover{
    opacity: 1;
}

.formatsGroup .formatOption.activeFormat .checkSymbol{
    display: flex;
}

.formatsGroup .formatOption .checkSymbol i{
    color: var(--color-primary);
}

.formatsGroup .formatOption .contentMain i{
    font-size: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 14px;
}

.formatsGroup .formatOption .contentMain span{
    font-size: 0.9em;
}

.formatsGroup .formatOption .contentMain{
    padding: 1em 0em;
}

.formatsGroup .formatOption button{
    width: 100%;
    margin-top: 0.5em;
    background:var(--color-text);
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em 0em;
    cursor: pointer;
    border-radius: 0em 0em 0.3em 0.3em;
}

.formatsGroup .formatOption button i{
    margin-left: 0.5em;
    font-size: 1.5em;
}

.formatsGroup .formatOption button i, 
.formatsGroup .formatOption button b{
    color: var(--color-primary);
}

.formatsGroup .formatOption.activeFormat{
    border: 3px solid var(--color-text);
}
.Configuraciones {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.Configuraciones .mainConf {
  width: 78%;
  height: 100%;
  box-sizing: border-box;
  padding: 0em 0em;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 70px;
}

.Configuraciones .mainConf .sectionMainConf {
  width: 700px;
  margin-bottom: 2em;
}

.Configuraciones .mainConf .sectionMainConf h4 {
  font-weight: 500;
  color: var(--color-text);
}

.Configuraciones .mainConf::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.Configuraciones .mainConf::-webkit-scrollbar-thumb {
  background: var(--color-border);
  border-radius: 2em;
}

.Configuraciones .mainConf .sectionMainConf .confSection {
  min-height: 100px;
  overflow: hidden;
  background: var(--color-primary);
  border-radius: 10px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
}

.labelConf {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--color-border);
  padding: 1em 1em;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
}

.sectionMainConf .confSection .labelConf:last-child {
  border-bottom: none !important;
}

.labelConf a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.labelConf span {
  font-size: 0.9em;
  color: var(--color-text);
}

.labelConf input {
  border: none;
  border-radius: 5px;
  background: var(--color-primary-variant);
  padding: 0.5em 1em;
  outline: none;
  font-size: 0.9em;
  width: 250px;
  font-family: inherit;
  color: var(--color-text);
}

.labelConf select {
  border: none;
  border-radius: 5px;
  background: var(--color-primary-variant);
  width: 280px;
  padding: 0.5em 1em;
  outline: none;
  font-size: 0.9em;
  font-family: inherit;
  color: var(--color-text);
}

.saveConf,
.deleteAccount {
  margin: 1em;
  background: var(--color-success);
  color: white;
  font-weight: 500;
  font-family: inherit;
  border-radius: 5px;
  border: none;
  padding: 0.5em 1em;
  font-size: 1em;
}

.presentProfile {
  border-bottom: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deleteAccount {
  background: var(--color-error);
  color: white;
}

.present .avatar i {
  font-size: 2em;
  fill: var(--color-text);
}

.present .avatar {
  margin-right: 1em;
}

.present {
  display: flex;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
}

.present .infoUser {
  align-content: center;
}

.present .infoUser h4 {
  font-size: 1em;
  margin-bottom: 0em !important;
  margin-top: 0em !important;
  font-weight: bold !important;
}

.present .infoUser p {
  font-size: 0.8em;
  margin: 0;
  color: var(--color-text);
}

.labelConf .complateLabel {
  width: 100%;

  flex-direction: column;
  display: flex;
}

.labelConf .complateLabel span {
  margin-bottom: 1em;
}

.labelConf .complateLabel input,
.labelConf .complateLabel textarea {
  background: var(--color-primary-variant);
  color: var(--color-text);
  border: none;
  border-radius: 5px;
  width: 100%;
  padding: 0.5em;
  font-size: 0.9em;
  font-family: inherit;
  outline: none;
  box-sizing: border-box;
}

.labelConf .complateLabel input::placeholder,
.labelConf .complateLabel textarea::placeholder {
  color: var(--color-text);
}

.labelConf .complateLabel .gridColors {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelConf .complateLabel .gridColors .colorItem {
  border-radius: 50%;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
  border: 2px solid var(--color-text);
  cursor: pointer;
  width: 60px;
  height: 60px;
  /* overflow: hidden; */
  position: relative;
}

.containerPrimary {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

.labelConf .complateLabel .gridColors .colorItem .primary {
  width: 50%;
  height: 100%;
}

.labelConf .complateLabel .gridColors .colorItem i {
  font-size: 2em;
  color: var(--color-text);
  position: absolute;
  top: -0.6em;
  right: -0.6em;
  display: none;
}

.labelConf .complateLabel .gridColors .colorItem.active {
  border: 3px solid var(--color-text) !important;
}

.labelConf .complateLabel .gridColors .colorItem.active i {
  display: inline-block;
}

.logOutUserBtn {
  background: var(--color-error) !important;
  color: white;
}

@media screen and (max-width: 1024px) {
  .Configuraciones {
    flex-direction: column-reverse;
  }
  .indexConf {
    width: 100%;
    height: max-content;
  }
  .Configuraciones .mainConf {
    width: 100%;
  }
  .Configuraciones .mainConf .sectionMainConf {
    width: 95%;
  }
  .labelConf .complateLabel .gridColors {
    flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
    justify-content: space-around;
  }
}

.Notificaciones {
  width: 100%;
  align-self: flex-start;
}

.Notificaciones .mainNotification {
  width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification {
  width: 100%;
}

.Notificaciones .mainNotification .containerNotifications {
  width: 100%;
  max-width: 600px;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item {
  margin: 0em;
  margin-right: 0em;
  width: 100%;
  background: var(--color-primary);
  margin-bottom: 1em;
  text-decoration: none !important;
}

.Notificaciones .mainNotification .emptyNotifications i {
  font-size: 5em;
  color: var(--color-text);
  margin-top: 1em;
}

.Notificaciones .mainNotification .emptyNotifications h4,
.Notificaciones .mainNotification .emptyNotifications p {
  color: var(--color-text);
}

.Notificaciones .mainNotification .emptyNotifications h4 {
  font-size: 1.8em;
}

.Notificaciones .mainNotification .emptyNotifications p {
  font-size: 0.8em;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item .info b {
  font-size: 1.5em;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item .info p {
  font-size: 1.2em !important;
}

.Notificaciones .mainNotification .containerNotifications .sectionNotification .itemsSectionNotification .item .date {
  font-size: 1em;
}

.Notificaciones
  .mainNotification
  .containerNotifications
  .sectionNotification
  .itemsSectionNotification
  .item
  .checkNotification {
  font-size: 1.2em;
  border: none;
}

.typeNotification {
  position: absolute;
  top: 0;
  left: -3em;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--color-text);
  color: var(--color-primary) !important;
  font-size: 1.3em;
}

.typeNotification.llavePorVencer {
  background: #f1cd00;
  color: black;
}

.typeNotification.llaveVencida,
.typeNotification.documentoRechazado {
  background: var(--color-error);
  color: white !important;
}

.typeNotification.documentoRecibido {
  background: var(--color-success);
  color: white !important;
}

.typeNotification.migracionDocumentos,
.typeNotification.documentoAceptado {
  background: var(--color-info);
  color: white !important;
}

.Sidebar {
  width: 18%;
  height: 100vh;
  height: 100dvh;
  background: var(--color-primary);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  transition: all 0.3s !important;
  font-size: 0.9em;
}

.Sidebar::-webkit-scrollbar {
  background: var(--color-primary);
  width: 0.5em;
}

.Sidebar::-webkit-scrollbar-thumb {
  background: var(--color-primary-variant);
  border-radius: 2em;
  margin-right: 1em;
}

.Sidebar h2 {
  color: var(--color-primary-variant);
}

.Sidebar .logo {
  width: 70%;
}

.Sidebar .logo a {
  display: block;
  width: 100%;
}

.Sidebar .logo a img {
  width: 100%;
  height: auto;
}

.menuSideBar {
  width: 100%;
  padding: 0;
}

.menuSideBar li {
  margin-bottom: 1em;
}

.menuSideBar li a {
  color: var(--color-text);
  text-decoration: none;
  font-size: 1em !important;
  transition: all 0.3s;
  width: 100%;
  display: inline-block;
}

.menuSideBar .itemMenuSidebar,
.menuSideBar .itemMenuSidebarWithList {
  margin-bottom: 0.5em;
  outline: none;
}

.menuSideBar .itemMenuSidebarWithList button:hover,
.menuSideBar .itemMenuSidebarWithList button:focus {
  font-weight: bold;
  color: var(--color-text) !important;
  background: var(--color-primary-variant) !important;
  border-radius: 10px;
  outline: none;
}

.menuSideBar .itemMenuSidebar a:focus,
.menuSideBar .itemMenuSidebar a:focus {
  color: var(--color-text);
  outline: none;
}

.menuSideBar .itemMenuSidebar a {
  box-sizing: border-box;
  padding: 0.5em 1em;
  outline: none;
}

.menuSideBar .itemMenuSidebar a:hover,
.menuSideBar .itemMenuSidebar a:focus {
  font-weight: bold;
  color: var(--color-text) !important;
  background: var(--color-primary-variant) !important;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
}

.menuSideBar .itemMenuSidebarWithList button:hover svg,
.menuSideBar .itemMenuSidebarWithList button:focus svg {
  fill: var(--color-text) !important;
}

.menuSideBar .itemMenuSidebarWithList ul li a:hover,
.menuSideBar .itemMenuSidebarWithList ul li a:focus,
.menuSideBar .itemMenuSidebarWithList ul li button:hover,
.menuSideBar .itemMenuSidebarWithList ul li button:focus {
  outline: none;
  font-weight: 600;
}

.menuSideBar li button {
  background: transparent;
  font-family: inherit;
  color: var(--color-text);
  border: 0;
  font-size: 1em !important;
  padding: 1em;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.menuSideBar li button svg {
  width: 1em;
  height: 1em;
  fill: white;
  pointer-events: none;
}

.menuSideBar li ul {
  padding-left: 2em;
  margin-top: 0.5em;
  flex-direction: column;
  display: none;
}

.menuSideBar li button svg {
  transform: rotate(-90deg);
}

.menuSideBar .submenuToggled button svg {
  transform: rotate(360deg);
}

.submenuToggled ul {
  display: flex !important;
}

.menuSideBar li ul li {
  list-style: none;
  margin-bottom: 0.5em;
}

.menuSideBar li ul li a {
  font-size: 0.9em !important;
  margin-bottom: 0.5em;
}

.linkSubMenuActive {
  font-weight: bold;
}

.linkLevelOneActive {
  font-weight: bold;
  color: var(--color-text) !important;
  background: var(--color-primary-variant);
  display: inline-block;
  width: 100%;
  border-radius: 10px;
}

.submenuToggled {
  background: var(--color-primary-variant);
  border-radius: 10px;
  padding: 0.5em 0em;
  transition: all 0.3s;
}

.submenuToggled button {
  color: var(--color-text) !important;
  padding: 1em !important;
  font-weight: bold;
}

.submenuToggled button svg {
  fill: var(--color-text) !important;
}

.submenuToggled ul li a {
  color: var(--color-text);
}

.submenuToggled ul li a:hover {
  font-weight: bold;
}

.toggleSidebar {
  margin-top: auto;
  margin-bottom: 1em;
  display: flex;
  background: transparent;
  width: 90%;
  border: none;
  color: var(--color-text);
  font-size: 1em;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
}

.toggleSidebar .icon {
  margin-right: 1em;
  background: var(--color-primary-variant);
  padding: 0.5em;
  border-radius: 50%;
  display: flex;
  transition: all 0.3s;
  pointer-events: none;
}

.toggleSidebar .icon svg {
  width: 1em;
  height: 1em;
  transform: rotate(90deg);
  transition: all 0.3s;
  fill: var(--color-text);
  pointer-events: none;
}

.Sidebar.toggled {
  position: absolute;
  left: -20%;
}

.Sidebar.toggled + .mainViewer {
  width: 100%;
}

.Sidebar.toggled .toggleSidebar {
  width: max-content;
  position: fixed;
  bottom: 1em;
  left: 1em;
  z-index: 1000;
}

.Sidebar.toggled .toggleSidebar .icon {
  background: var(--color-text);
  pointer-events: none;
}

.Sidebar.toggled .toggleSidebar .icon svg {
  fill: var(--color-primary);
  transform: rotate(-90deg);
}

.Sidebar.toggled .toggleSidebar span {
  display: none;
}

@media screen and (max-width: 1024px) {
  .Sidebar {
    width: 50%;
    position: absolute;
    transition: all .5s ease-in-out !important;
    z-index: 100;
  }
  .Sidebar.toggled {
    position: absolute;
    transition: all .5s ease-in-out !important;
    left: -50%;
  }
}

@media screen and (max-width: 768px) {
  .menuSideBar li {
    font-size: 14px;
  }
  .Sidebar {
    z-index: 900 !important;
  }
}

@media screen and (max-width: 576px) {
  .menuSideBar li {
    font-size: 12px;
  }
}

.loadCompleteScreen {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000000;
}

@keyframes logoTtileo {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}

.loadCompleteScreen .logo img {
  width: 400px;
  height: auto;
  animation: logoTtileo 1s ease-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.loadCompleteScreen.hiden {
  display: none;
}

.AlertPermissionNotification{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EF9D38;
    color: white;
    height: 40px;
    box-sizing: border-box;
    padding: 0em 1em;
}

.AlertPermissionNotification.hiden{
    display: none !important;
}

.AlertPermissionNotification p{
    font-weight: bold;
}

.AlertPermissionNotification .warningSymbol{
    margin-right: auto;
}

.AlertPermissionNotification button{
    margin-left: 1em;
    background: transparent;
    padding: 0em;
    text-decoration: underline;
    font-size: 1em;
    font-family: inherit;
    font-weight: bold;
    cursor: pointer;
}

.AlertPermissionNotification .closeAlertPermissionNotification{
    margin-left: auto;
}

.AlertPermissionNotification .closeAlertPermissionNotification i{
    font-size: 2em;
}

.AlertPermissionNotification.show ~ .mainViewer, .AlertPermissionNotification.show + .Sidebar{
    height: calc(100% - 40px) !important;
}
.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

.App.fontSize18 {
  font-size: 18px !important;
}

.App.fontSize16 {
  font-size: 16px !important;
}

.App.fontSize14 {
  font-size: 14px !important;
}

.App.fontSize12 {
  font-size: 12px !important;
}

.mainViewer {
  width: 82%;
  background: var(--color-primary-variant);
  height: 100%;
  transition: all 0.15s;
  overflow: hidden;
}

.mainViewer .router {
  color: var(--color-text);
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainViewer .router h2 {
  color: var(--color-text);
  width: 100%;
  font-size: 2em;
  text-align: center;
}

.Toastify__toast-container--top-right {
  bottom: 1em !important;
  right: 1em !important;
  top: auto !important;
  font-family: inherit;
  width: auto !important;
  max-width: 50% !important;
  z-index: 1000000000 !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  font-family: inherit !important;
}

.mainViewerLogin {
  width: 100%;
  height: 100vh;
  background: var(--color-primary-variant);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadContent {
  position: fixed;
  bottom: 1em;
  display: flex;
  align-items: center;
  left: 22%;
  background: white;
  box-sizing: border-box;
  padding: 0.2em 2em;
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
}

@keyframes snippetLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadContent .snippet {
  margin-right: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border: 5px solid black;
  border-left: 5px solid #555;
  border-radius: 50%;
  animation: snippetLoad 1s ease-out;
  animation-iteration-count: infinite;
  position: relative;
}

.loadContent span.porcent {
  position: absolute;
  transform: rotate(0deg);
  font-size: 0.7em;
  display: block;
  margin-left: 1em;
}

.movilScreen {
  display: none !important;
  width: 100%;
  /* height: 100vh; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
}

.movilScreen .navRegistro {
  border-bottom: 1px solid black;
}

.movilScreen .mainMovilScreen {
  width: 90%;
  max-width: 750px;
  margin: 0em auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  box-sizing: border-box;
}

.movilScreen .mainMovilScreen h3 {
  font-size: 2em;
  margin: 0px 0px;
  font-weight: bold;
}

.movilScreen .mainMovilScreen p {
  font-size: 1em;
  text-align: left;
}

.movilScreen .mainMovilScreen img {
  width: 90%;
  height: auto;
}

.movilScreen .mainMovilScreen a {
  background: #0dac22 !important;
  color: white;
  padding: 0.5em;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  border-radius: 1em 1em 0em 0em;
  position: fixed;
  width: 100%;
  bottom: 0em;
  left: 0em;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1em;
}

.movilScreen .mainMovilScreen a i {
  color: white;
  margin-left: 1em;
  font-size: 2em !important;
}

.gridMovilScreen {
  width: 90%;
  max-width: 750px;
  grid-gap: 3em;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 10em;
  margin-top: 4em;
}

.gridMovilScreen img {
  width: 100%;
  height: auto;
  border-radius: 1em;
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

@media screen and (max-width: 1200px) {
  /* .App {
    display: none;
  } */

  .movilScreen {
    display: flex !important;
  }
}

@media screen and (max-width: 1024px) {
  .mainViewer {
    width: 100%;
  }
}

@media screen and (max-width: 780px) {
  .movilScreen .mainMovilScreen h3 {
    font-size: 1.5em;
    margin: 0px 0px;
    font-weight: bold;
  }

  .gridMovilScreen {
    grid-template-columns: 1fr 1fr;
  }
}

*,
*::after,
*::before {
  color: var(--color-text);
}

.react-calendar {
  background-color: var(--color-primary);
}

.modal-factura {
  position: absolute;
  width: 500px;
  height: 300px;
  background-color: var(--color-primary-variant);
  z-index: 99999;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  border-radius: 10px;
}

.close-modal-fac {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--color-primary);
  color: var(--color-text);
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-modal-fac {
  width: 150px;
  background-color: var(--color-primary);
  color: var(--color-text);
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 10px;
}

.title-modal-fac {
  margin-left: 20px;
}

.text-modal-fac {
  text-align: center;
  margin: 0;
}

.container-title-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ShareDocumentoModal.toggled{
    display: none;
}

.ShareDocumentoModal{
    position: fixed;
    display: flex;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: flex-start;
}

.ShareDocumentoModal .cardShare{
    width: 100%;
    max-width: 700px;
    background: var(--color-primary);
    margin-top: 2em;
    border-radius: 1em;
    box-sizing: border-box;
    padding: 1em 2em;
    overflow: hidden;
}

.ShareDocumentoModal .cardShare h3{
    font-weight: 400;
    font-size: 1.4em;
}

.ShareDocumentoModal .cardShare .navCard{
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ShareDocumentoModal .cardShare .navCard button{
    background: transparent;
    cursor: pointer;
}


.ShareDocumentoModal .cardShare .optionsShareContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 1em 0em;
    padding-bottom: 2em;
    padding-top: 1em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}

/* .ShareDocumentoModal .cardShare .optionsShareContainer button{
    margin: 0em 1.5em 0em 0em;
} */

.copyContainer{
    background: var(--color-primary-variant);
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    padding: 0.5em;
    border-radius: 0.5em;
}

.copyContainer span{
    font-size: 0.85em;
}

.copyContainer{
    margin-bottom: 1em;
}

.copyContainer button{
    cursor: pointer;
}
.MethodsPages {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-bottom: 2em;
  }
  
  .MethodsPages::-webkit-scrollbar {
    background: transparent;
    width: 0.5em;
  }
  
  .MethodsPages::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 2em;
  }

  .FormMethod .FormContent{
    flex-wrap: wrap;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    align-content: flex-start !important;
  }

  .FormMethod .FormContent .cardContainer{
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0em;
  }

  .FormMethod .FormContent input{
    background: var(--color-primary);
  }

  .FormMethod .FormContent .groupInputs{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
  }

  .FormMethod .Form{
    width: 600px;
  }

  .toggleCustom{
    width: 54px;
    margin-top: auto;
  }
  
  .FormMethod .FormContent .saveForm{
    margin-bottom: auto !important;
    font-size: 1em;
    padding: 0.5em 2em;
    border-radius: 0.5em;
    color: white;
    margin-top: 1em;
    margin-left: 2%;
    font-size: 1.2em;
    background: var(--color-success);
    align-self: center;
  }
  .FormMethod .FormContent .saveForm i{
    margin-left: 0.5em;
    color: white;
  }
.HistorialPages {
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    padding-bottom: 2em;
  }
  
  .HistorialPages::-webkit-scrollbar {
    background: transparent;
    width: 0.5em;
  }
  
  .HistorialPages::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 2em;
  }

  .pandingAlert{
      width: 95%;
      border-radius: 0.5em;
      box-sizing: border-box;
      padding: 1em 2em;
      margin: 0em auto 2em auto;
      background: var(--color-error);
      color: white;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
  }

  .pandingAlert h3{
      color: white;
      font-size: 1.5em;
      margin: 0em 0em;
      font-weight: bold;
      width: 100%;
  }

  .pandingAlert p{
      color: white;
      font-size: 0.9em;
      width: 70%;
  }

  .pandingAlert a{
      border-radius: 2em;
      color: black;
      background: white;
      padding: 0.5em 2em;
      font-family: inherit;
      font-size: 1em;
  }
.FacturaElectronicas {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 4.5em;
}

.FacturaElectronicas::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.FacturaElectronicas::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}
.btn-modal-fac{
  cursor: pointer;
}
.styles_header__3rSbw {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  padding-top: 1rem;
  margin-block-start: 0;
  margin-block-end: 1rem;
}

.styles_clean__Hv6Zf {
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
  background-color: inherit;
  border: none;
  font-size: 0.9rem;
  font-family: inherit;
  color: #888888;
  text-decoration: underline;
  transition: color 100ms linear;
}

.styles_clean__Hv6Zf:hover {
  color: #4f4f4f;
}

.styles_title__15N3p {
  margin: 0;
  width: 100%;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: left;
}

.styles_container__2Bb6h {
  width: 100%;
  padding: 0.5rem;
}

.styles_exoInfo__T_WhN {
  padding-inline: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
}

.styles_exoInfo__T_WhN .styles_exoInfoItem__3ITq7 strong {
  font-weight: 500;
  font-size: 1rem;
  display: block;
  margin-bottom: 0.25rem;
}

.styles_exoInfo__T_WhN .styles_exoInfoItem__3ITq7 p {
  margin: 0;
}

.FacturaElectronicaPorExportacion {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.FacturaElectronicaPorExportacion::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.FacturaElectronicaPorExportacion::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.TiquetaElectronica {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.TiquetaElectronica::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.TiquetaElectronica::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}


.ProformaElectronica {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.ProformaElectronica::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.ProformaElectronica::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.NotaCredito {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.NotaCredito::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.NotaCredito::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}

.NotaDebito {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-bottom: 2em;
}

.NotaDebito::-webkit-scrollbar {
  background: transparent;
  width: 0.5em;
}

.NotaDebito::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 2em;
}


label.text {
  box-sizing: border-box;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.validCedula {
  margin: 1em 0em !important;
}

.validCedulaInput {
  width: 300px;
  font-size: 1.2em !important;
}

.validcedulaSlide .steps span {
  font-size: 1.2em;
}

.validcedulaSlide h4,
.validcedulaSlide p {
  text-align: center;
  width: 450px !important;
  margin-bottom: 0.5em !important;
}

.validcedulaSlide h4 {
  font-size: 1.8em !important;
}

.validcedulaSlide p {
  font-size: 1em !important;
}

.validcedulaSlide label {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.validcedulaSlide {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

label.text input,
label.text select,
label.text textarea {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: #eee;
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.registroForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 100px;
}

.registroForm h4 {
  width: 100%;
}

.registroForm button {
  margin-bottom: 1em;
}

.cardData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

/* --------------- */

.Registro {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  background: white;
}

.Registro .FormLoginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0em 3em;
  box-sizing: border-box;
  margin: auto;
}

.Registro .FormLoginContainer .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Registro .FormLoginContainer .logo img {
  width: 300px;
  height: auto;
}

.Registro .FormLoginContainer .titleRegistro {
  font-size: 2em;
  margin-bottom: 0em;
}

.Registro .FormLoginContainer .Form {
  background: white;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  height: 80vh;
}

.Registro .FormLoginContainer .Form {
  box-shadow: none !important;
  min-height: 100vh;
  height: auto !important;
}

.Registro .FormLoginContainer .Form .carrusel {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.4s;
 padding-bottom: 40px;
}

.Registro .FormLoginContainer .Form .slide {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2em 3em;
}

.illustrationRegistrar img {
  width: 100%;
}

.Registro .FormLoginContainer .Form h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.Registro .FormLoginContainer .Form p {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.Registro .FormLoginContainer .Form label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  margin-top: 0em;
  margin-bottom: 0em;
  padding: 1em;
  box-sizing: border-box;
}

.gridPlanes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
}

.gridPlanes .plan {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #666;
  border-radius: 1em;
  height: 80px;
}

.Registro .FormLoginContainer .Form label .field {
  width: 100%;
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 10px;
  height: 45px;
  overflow: hidden;
  margin-top: 0.5em;
}

.Registro .FormLoginContainer .Form label .field input {
  width: 100%;
  background: transparent;
  height: 100%;
  border: none;
  padding: 0em 1em;
  outline: none;
  font-size: 1.1em;
}

.Registro .FormLoginContainer .Form label .field .options {
  height: 100%;
}

.Registro .FormLoginContainer .Form label .field .options button {
  cursor: pointer;
  border: none;
  background: #ddd;
  height: 100%;
  padding: 0.8em;
  box-sizing: border-box;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  height: 1.5em;
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive {
  background: #ddd;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive svg {
  height: 1.8em;
  fill: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active {
  background: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active svg {
  height: 1.8em;
  fill: white;
}

.Registro .FormLoginContainer .Form label .field .options button:hover svg {
  opacity: 1 !important;
}

.optionsSecondaryForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8em;
}

.optionsSecondaryForm a {
  font-size: 0.9em;
  width: auto;
  display: inline-block;
  text-align: right;
  color: black;
}

.optionsSecondaryForm label {
  width: 50% !important;
}

.btnRegistro {
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0.5em 2.5em;
  font-family: inherit;
  background: black;
  border: none;
  color: white;
  border-radius: 10px;
  outline: none;
}

.btnRegistro:hover {
  background-color: #111;
}

.btnRegistro:disabled {
  cursor: not-allowed;
  background-color: #d6d6d6;
  color: #b6b6b6;
}

.cardDataInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.cardDataInput label {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.cardDataInput label input {
  width: 100%;
  box-sizing: border-box;
}

.tablePlanes {
  background: #f9f9f9;
  border-radius: 0.5em;
  overflow: hidden;
  width: 80% !important;
}

.planesStep {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.infoStep {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.infoPayStep {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

.infoStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  text-align: left;
}

.infoStep p {
  width: 500px;
  font-size: 1em !important;
  text-align: left;
}

.actionFixed {
  position: fixed;
  height: 80px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #666;
  background: white;
}

.tables {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 1em;
}

.planesStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
}

.infoPayStep p {
  width: 600px;
  font-size: 1em !important;
  align-items: left;
}

.infoPayStep .steps {
  width: 600px;
}

.infoPayStep h4 {
  width: 600px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  align-items: left;
}

.planesStep p {
  width: 500px;
  font-size: 1em !important;
}

.tablePlanes th {
  font-size: 0.8em;
}

.tablePlanes th h4 {
  text-align: center;
  width: 100%;
}

.tablePlanes td {
  font-size: 0.8em;
}

.backButton {
  outline: none;
  background: transparent;
  border: none;
  margin-right: 1em;
  transform: rotate(90deg);
  cursor: pointer;
  padding: 0;
}

.backButton svg {
  width: 1.5em;
  height: 1.5em;
}

.tablePlanes {
  table-layout: fixed;
  width: 48% !important;
  border-collapse: collapse;
  opacity: 0.4;
  cursor: pointer;
}

.tablePlanes:hover {
  opacity: 1;
}

.resumen {
  width: 45%;
  padding: 1.5em;
  box-sizing: border-box;
  background: #eee;
  min-height: 400px;
  margin-left: auto;
  border-radius: 1em;
}

.resumen h4 {
  font-weight: 500 !important;
}

.resumen ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.resumen ul li select {
  padding: 0.2em;
  background: white;
}

.tablePlanes.active {
  opacity: 1;
}

.tablePlanes td {
  width: 50%;
  padding: 0.8em;
  font-size: 0.9em;
  margin: 0em 1em;
}

.tablePlanes th {
  width: 50%;
  padding: 0.5em;
  margin: 0em 1em;
}

.tablePlanes tbody tr:nth-child(2n) {
  background: #eee;
}

.tablePlanes th.persona {
  background: rgb(84, 86, 204);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.asistido {
  background: rgb(246, 126, 56);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.juridico {
  background: rgb(207, 64, 64);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes .priceTable th span {
  text-align: center !important;
  color: white;
  font-size: 0.7em;
  font-weight: normal;
  margin: 0em;
  margin-left: 1em;
}

.priceTable th b {
  font-size: 1.4em;
  margin-bottom: 0em !important;
  font-weight: 500;
}

.priceTable .opacith {
  opacity: 0.8;
}

.btnPlanes {
  margin: auto;
  margin-top: 2em !important;
}

.navRegistro {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0.5em 1em;
  justify-content: space-between;
  border-bottom: 1px solid black;
  align-items: center;
}

.navRegistro img {
  height: 60px;
}

.navRegistro a {
  text-transform: uppercase;
  color: black;
  text-decoration: none;
}

.FormInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.paymentConfirmationModal {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 696969;
}

.paymentConfirmationModal .content h3 {
  margin-bottom: 0;
}

.paymentConfirmationModal .content {
  background-color: white;
  min-width: 30vw;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.paymentConfirmationModal .content .phrase {
  margin-block: 0.25rem;
}

.paymentConfirmationModal .content button {
  margin: 1rem 0;
}

@media screen and (max-width: 1024px) {
  .Registro .FormLoginContainer .Form .planesStep {
    flex-wrap: nowrap !important;
    width: 100% !important;
    padding: 0 !important;
  }
  .Registro .FormLoginContainer {
    width: 100%;
    padding: 0 !important;
  }
  .Registro .FormLoginContainer .Form {
    margin-bottom: 200px;
  }
  /* .infoPayStep .steps {
    width: 90%;
  } */
  .Registro .FormLoginContainer .Form h4 {
    text-align: center;
  }
  .Registro .FormLoginContainer .Form .slide {
    flex-direction: column;
    padding: 0 !important;
  }
  .infoPayStep {
    width: 100% !important;
  }
  .cardData {
    width: 100% !important;
  }
  .cardDataInput {
    flex-direction: column !important;
  }
  .cardDataInput label {
    width: 100% !important;
  }
  .resumen {
    width: 100% !important; 
    min-height: none;
  }
  .tables .tablePlanes {
    width: 100% !important;
  }
}
label.text {
  box-sizing: border-box;
  padding: 0.8em 1em;
  display: flex;
  flex-direction: column;
  font-size: 0.85em;
}

.validCedula {
  margin: 1em 0em !important;
}

.validCedulaInput {
  width: 300px;
  font-size: 1.2em !important;
}

.validcedulaSlide .steps span {
  font-size: 1.2em;
}

.validcedulaSlide h4,
.validcedulaSlide p {
  text-align: center;
  width: 450px !important;
  margin-bottom: 0.5em !important;
}

.validcedulaSlide h4 {
  font-size: 1.8em !important;
}

.validcedulaSlide p {
  font-size: 1em !important;
}

.validcedulaSlide label {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.validcedulaSlide {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

label.text input,
label.text select,
label.text textarea {
  padding: 0.8em 0.8em;
  border-radius: 5px;
  background: #eee;
  border: none;
  margin-top: 0.5em;
  font-size: 1em;
  font-family: inherit;
  outline: none;
}

.registroForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.registroForm h4 {
  width: 100%;
}

.registroForm button {
  margin-bottom: 1em;
}

.cardData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

/* --------------- */

.Registro {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  background: white;
}

.Registro .FormLoginContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0em 3em;
  box-sizing: border-box;
  margin: auto;
}

.Registro .FormLoginContainer .logo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Registro .FormLoginContainer .logo img {
  width: 300px;
  height: auto;
}

.Registro .FormLoginContainer .titleRegistro {
  font-size: 2em;
  margin-bottom: 0em;
}

.Registro .FormLoginContainer .Form {
  background: white;
  width: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  height: 80vh;
}

.Registro .FormLoginContainer .Form {
  box-shadow: none !important;
  min-height: 100vh;
  height: auto !important;
}

.Registro .FormLoginContainer .Form .carrusel {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.4s;
}

.Registro .FormLoginContainer .Form .slide {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 2em 3em;
}

.illustrationRegistrar img {
  width: 100%;
}

.Registro .FormLoginContainer .Form h4 {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.Registro .FormLoginContainer .Form p {
  font-size: 0.9em;
  margin-bottom: 1em;
}

.Registro .FormLoginContainer .Form label {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  margin-top: 0em;
  margin-bottom: 0em;
  padding: 1em;
  box-sizing: border-box;
}

.gridPlanes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1em;
}

.gridPlanes .plan {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: #666;
  border-radius: 1em;
  height: 80px;
}

.Registro .FormLoginContainer .Form label .field {
  width: 100%;
  display: flex;
  align-items: center;
  background: #eee;
  border-radius: 10px;
  height: 45px;
  overflow: hidden;
  margin-top: 0.5em;
}

.Registro .FormLoginContainer .Form label .field input {
  width: 100%;
  background: transparent;
  height: 100%;
  border: none;
  padding: 0em 1em;
  outline: none;
  font-size: 1.1em;
}

.Registro .FormLoginContainer .Form label .field .options {
  height: 100%;
}

.Registro .FormLoginContainer .Form label .field .options button {
  cursor: pointer;
  border: none;
  background: #ddd;
  height: 100%;
  padding: 0.8em;
  box-sizing: border-box;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  height: 1.5em;
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button svg {
  pointer-events: none;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive {
  background: #ddd;
}

.Registro .FormLoginContainer .Form label .field .options button.desactive svg {
  height: 1.8em;
  fill: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active {
  background: black;
}

.Registro .FormLoginContainer .Form label .field .options button.active svg {
  height: 1.8em;
  fill: white;
}

.Registro .FormLoginContainer .Form label .field .options button:hover svg {
  opacity: 1 !important;
}

.optionsSecondaryForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.8em;
}

.optionsSecondaryForm a {
  font-size: 0.9em;
  width: auto;
  display: inline-block;
  text-align: right;
  color: black;
}

.optionsSecondaryForm label {
  width: 50% !important;
}

.btnRegistro {
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0.5em 2.5em;
  font-family: inherit;
  background: black;
  border: none;
  color: white;
  border-radius: 10px;
  outline: none;
}

.btnRegistro:hover {
  background-color: #111;
}

.btnRegistro:disabled {
  cursor: not-allowed;
  background-color: #d6d6d6;
  color: #b6b6b6;
}

.cardDataInput {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.cardDataInput label {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.cardDataInput label input {
  width: 100%;
  box-sizing: border-box;
}

.tablePlanes {
  background: #f9f9f9;
  border-radius: 0.5em;
  overflow: hidden;
  width: 80% !important;
}

.planesStep {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.infoStep {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
}

.infoPayStep {
  justify-content: center !important;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  align-content: center !important;
}

.infoStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  text-align: left;
}

.infoStep p {
  width: 500px;
  font-size: 1em !important;
  text-align: left;
}

.actionFixed {
  position: fixed;
  height: 80px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #666;
  background: white;
}

.tables {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 1em;
}

.planesStep h4 {
  width: 500px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
}

.infoPayStep p {
  width: 600px;
  font-size: 1em !important;
  align-items: left;
}

.infoPayStep .steps {
  width: 600px;
}

.infoPayStep h4 {
  width: 600px;
  font-size: 1.8em !important;
  margin-bottom: 0em !important;
  align-items: left;
}

.planesStep p {
  width: 500px;
  font-size: 1em !important;
}

.tablePlanes th {
  font-size: 0.8em;
}

.tablePlanes th h4 {
  text-align: center;
  width: 100%;
}

.tablePlanes td {
  font-size: 0.8em;
}

.backButton {
  outline: none;
  background: transparent;
  border: none;
  margin-right: 1em;
  transform: rotate(90deg);
  cursor: pointer;
  padding: 0;
}

.backButton svg {
  width: 1.5em;
  height: 1.5em;
}

.tablePlanes {
  table-layout: fixed;
  width: 48% !important;
  border-collapse: collapse;
  opacity: 0.4;
  cursor: pointer;
}

.tablePlanes:hover {
  opacity: 1;
}

.resumen {
  width: 45%;
  padding: 1.5em;
  box-sizing: border-box;
  background: #eee;
  min-height: 400px;
  margin-left: auto;
  border-radius: 1em;
}

.resumen h4 {
  font-weight: 500 !important;
}

.resumen ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.resumen ul li select {
  padding: 0.2em;
  background: white;
}

.tablePlanes.active {
  opacity: 1;
}

.tablePlanes td {
  width: 50%;
  padding: 0.8em;
  font-size: 0.9em;
  margin: 0em 1em;
}

.tablePlanes th {
  width: 50%;
  padding: 0.5em;
  margin: 0em 1em;
}

.tablePlanes tbody tr:nth-child(2n) {
  background: #eee;
}

.tablePlanes th.persona {
  background: rgb(84, 86, 204);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.asistido {
  background: rgb(246, 126, 56);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes th.juridico {
  background: rgb(207, 64, 64);
  color: white;
  font-size: 1em;
  width: 50%;
}

.tablePlanes .priceTable th span {
  text-align: center !important;
  color: white;
  font-size: 0.7em;
  font-weight: normal;
  margin: 0em;
  margin-left: 1em;
}

.priceTable th b {
  font-size: 1.4em;
  margin-bottom: 0em !important;
  font-weight: 500;
}

.priceTable .opacith {
  opacity: 0.8;
}

.btnPlanes {
  margin: auto;
  margin-top: 2em !important;
}

.navRegistro {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0.5em 1em;
  justify-content: space-between;
  border-bottom: 1px solid black;
  align-items: center;
}

.navRegistro img {
  height: 60px;
}

.navRegistro a {
  text-transform: uppercase;
  color: black;
  text-decoration: none;
}

.FormInfo {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.paymentConfirmationModal {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 696969;
}

.paymentConfirmationModal .content h3 {
  margin-bottom: 0;
}

.paymentConfirmationModal .content {
  background-color: white;
  min-width: 30vw;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.paymentConfirmationModal .content .phrase {
  margin-block: 0.25rem;
}

.paymentConfirmationModal .content button {
  margin: 1rem 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
}

.router {
  width: 100%;
}

select::placeholder,
input::placeholder,
input,
select {
  color: var(--color-text);
}

select:disabled,
input:disabled {
  background: var(--color--primary);
  color: var(--color-text);
  opacity: 0.8;
}

.schema_predeterminado {
  --color-primary: rgb(255, 255, 255);
  --color-primary-variant: rgb(247, 247, 249);
  --color-text: rgba(50, 50, 54, 0.87);
  --color-border: #aaaaaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-warning: #f59323;
  --color-error: #e23b3b;
}

.schema_negro {
  --color-primary: #343a40;
  --color-primary-variant: #212529;
  --color-text: #f8f9fa;
  --color-border: #e9ecef;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_azul_noche {
  --color-primary: #495057;
  --color-primary-variant: #343a40;
  --color-text: #f8f9fa;
  --color-border: #e9ecef;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_azul_oscuro {
  --color-primary: rgb(48, 51, 78);
  --color-primary-variant: rgb(40, 42, 66);
  --color-text: rgba(234, 234, 255, 0.87);
  --color-border: #aaa;
  --color-info: rgb(102, 108, 255);
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_purpura_oscura {
  --color-primary: #42285a;
  --color-primary-variant: #291135;
  --color-text: #ffffff;
  --color-border: #aaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_rojo_oscura {
  --color-primary: #85182a;
  --color-primary-variant: #641220;
  --color-text: #ffffff;
  --color-border: #aaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.schema_verde_oscura {
  --color-primary: #3a5a40;
  --color-primary-variant: #344e41;
  --color-text: #ffffff;
  --color-border: #aaa;
  --color-info: #4661f6;
  --color-success: #0dac22;
  --color-error: #e23b3b;
}

.listSection {
  width: 100%;
}

.addContent {
  display: flex;
  width: 180px;
  padding: 0.8em 1.5em;
  background: var(--color-text);
  color: var(--color-primary);
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.9em;
  border-radius: 10px;
  margin-left: auto;
  font-weight: 500;
  cursor: pointer;
  transition: 0.1s;
}

.addContent i::before {
  color: var(--color-primary);
  margin-left: 0.5em;
  font-size: 1.2em;
}

.addContent:hover {
  background: var(--color-primary-variant);
  color: var(--color-text);
}

.addContent:hover i {
  color: var(--color-text);
}

.navSection {
  margin-bottom: 1.5em;
  background: var(--color-primary);
  position: relative;
  padding: 0.8em 2em;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  border-radius: 0px 0px 10px 0px;
  transition: all 0.3s;
  border-top: 1px solid var(--color-border);
}

.navSection.float {
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.2);
  background: var(--color-primary);
}

.navSection label {
  padding: 0em 1em;
  display: flex;
  align-items: flex-start;
  font-size: 0.75em;
  flex-direction: column;
}

.navSection .sortBy {
  display: flex;
  align-items: center;
}

.navSection .sortBy button {
  margin-top: 0.5em;
}

.navSection .sortBy button {
  font-family: inherit;
  font-size: 1em;
  border-radius: 5px;
  padding: 0.4em 1em;
  border: none;
  color: var(--color-primary);
  background: var(--color-text);
  transition: all 0.3s;
  cursor: pointer;
}

.navSection .sortBy button:hover {
  border-bottom: 4px solid #111;
}

.navSection label .search input {
  font-size: 1em;
}

.navSection label .search {
  display: flex;
  width: 100%;
}

.navSection label .search button svg {
  height: 1em;
  width: 1em;
  fill: var(--color-text);
}

.navSection label .search input {
  border-radius: 5px 0px 0px 5px;
  margin-top: 0.3em;
  width: 100%;
  background: var(--color-primary-variant);
  color: var(--color-text);
}

.navSection label .search input::placeholder {
  color: var(--color-text);
}

.navSection label .search button {
  border: none;
  border-radius: 0px 5px 5px 0px;
  margin-top: 0.3em;
  background: var(--color-primary-variant);
  color: var(--color-text);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navSection svg {
  height: 1.5em;
  margin-right: 1em;
}

.navSection label input,
.navSection label select {
  padding: 0.5em 0.8em;
  border-radius: 5px;
  background: var(--color-primary-variant);
  color: var(--color-text);
  border: none;
  margin-top: 0.3em;
  font-family: inherit;
  outline: none;
  width: 100%;
  font-size: 1em !important;
}

.navSection .title {
  font-weight: 400;
  font-size: 0.85em;
  margin-right: 1em;
}

.addContent svg {
  width: 1.5em;
  height: 1.5em;
  fill: var(--color-primary);
  margin-right: 0em;
  margin-left: 1em;
}

.navSection.float .addContent {
  display: flex;
  padding: 0.8em 1.5em;
  align-items: center;
  border: none;
  font-size: 0.9em;
  border-radius: 10px;
  margin-left: auto;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-primary);
  background: var(--color-text);
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.navSection.float .addContent svg {
  width: 1.5em;
  height: 1.5em;
  fill: var(--color-primary);
  margin-right: 0em;
  margin-left: 1em;
}

.navSection.float label {
  color: var(--color-text);
}

.navSection.float .title {
  color: var(--color-text);
}

.listSection {
  padding: 0em 2em 0em 2em;
}

.listSection .headList {
  width: 100%;
}

.listSection .headList ul {
  width: 100%;
  display: flex;
  /* justify-content: space-around; */
  padding: 0em;
  margin: 0em;
  padding-bottom: 0.8em;
  border-bottom: 1px solid #aaa;
}

.listSection ul li {
  list-style: none;
  text-align: left;
  font-size: 0.85em;
  margin: 0em 0.5em;
}

.listSection ul li input {
  background: var(--color-primary-variant);
  border-radius: 5px;
  border: none;
  border-radius: 5px;
  padding: 0.5em;
}

.sidebarForm .actionForm button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}

.sidebarForm .actionForm button svg {
  fill: white;
  height: 1.5em;
  width: auto;
  margin-right: 1em;
}

.listSection ul li b {
  font-weight: 500;
}

.listSection .bodyList ul li b {
  font-weight: 700;
  color: var(--color-text);
}

.bodyList {
  width: 100%;
}

.bodyList ul {
  width: 100%;
  padding: 0;
}

.bodyList ul .itemBodyList {
  width: 100%;
  transition: all 0.3s;
}

.bodyList ul .itemBodyList .contentItemBodyList {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  background: var(--color-primary);
  padding: 1em 0.5em;
  margin-bottom: 0.5em;
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  /* cursor: pointer; */
  justify-content: space-between;
}

.bodyList ul .itemBodyList .contentItemBodyList li {
  margin: 0;
  font-size: 0.85em;
  margin: 0em 0.5em;
}

.bodyList ul .itemBodyList .contentItemBodyList li .activo::before {
  width: 0.5em !important;
  height: 0.5em !important;
  overflow: hidden;
  content: "";
  border-radius: 50%;
  margin-right: 0.5em;
  background: #31a863;
}

.bodyList ul .itemBodyList .contentItemBodyList li .desactivo::before {
  width: 0.5em !important;
  height: 0.5em !important;
  overflow: hidden;
  content: "";
  border-radius: 50%;
  margin-right: 0.5em;
  background: #d83f3f;
}

.bodyList ul .itemBodyList .contentItemBodyList li .activo,
.bodyList ul .itemBodyList .contentItemBodyList li .done {
  color: var(--color-success) !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li .info {
  color: var(--color-info) !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li .desactivo,
.bodyList ul .itemBodyList .contentItemBodyList li .cancel {
  color: #d83f3f !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li .panding {
  color: #f59323 !important;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bodyList ul .itemBodyList .contentItemBodyList li button,
.bodyList ul .itemBodyList .contentItemBodyList li a {
  background: transparent;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 0.5em;
}

.bodyList ul .itemBodyList .contentItemBodyList li button svg,
.bodyList ul .itemBodyList .contentItemBodyList li a svg {
  width: auto;
  height: 1.35em;
  pointer-events: none;
  fill: var(--color-text);
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnDelete svg {
  fill: #d83f3f;
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnAceptar svg {
  fill: #31a863;
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnDelete i {
  color: var(--color-error);
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnAceptar i {
  color: var(--color-success);
}

.bodyList ul .itemBodyList .contentItemBodyList li button i,
.bodyList ul .itemBodyList .contentItemBodyList li a i {
  font-size: 1.5em;
  pointer-events: none;
  color: var(--color-text);
}

.bodyList ul .itemBodyList .contentItemBodyList li button.btnDelete i {
  color: var(--color-error);
}

.bodyList ul .itemBodyList:hover {
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.3);
}

.noAction {
  pointer-events: none !important;
}

.countTable {
  font-size: 0.8em;
  margin-left: auto;
}

.col-1-2 {
  width: 5%;
}

.col-2-2 {
  width: 15%;
}

.col-1 {
  width: 10%;
}

.col-2 {
  width: 20%;
}

.col-3 {
  width: 30%;
}

.col-4 {
  width: 40%;
}

.col-5 {
  width: 50%;
}

.col-6 {
  width: 60%;
}

.col-7 {
  width: 70%;
}

.col-8 {
  width: 80%;
}

.col-9 {
  width: 90%;
}

.col-10 {
  width: 100%;
}

.hidden {
  display: none !important;
}

.emptyResultTable {
  width: 100%;
  height: 65vh;
  background: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.emptyResultTable span {
  font-size: 2em;
}

.loadContentTable {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes snippetLoad {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadContentTable .snippet {
  width: 5em;
  height: 5em;
  border: 4px solid var(--color-primary);
  margin-bottom: 1em;
  border-radius: 50%;
  border-left: 4px solid var(--color-text);
  animation: snippetLoad 1s ease-out;
  animation-iteration-count: infinite;
}

.paginationTable {
  position: block;
  background: var(--color-text);
  width: 30%;
  margin: 0 auto;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
}

.paginationTable .controllers {
  display: flex;
  padding: 1em;
  width: 100%;
  justify-content: center;
}

.paginationTable .controllers button {
  margin: 0em 1em;
  background: var(--color-primary);
  cursor: pointer;
}

.paginationTable .controllers button svg {
  fill: var(--color-text);
}

.paginationTable .controllers span {
  color: var(--color-primary);
}

.clearFilterButton {
  font-family: inherit;
  font-size: 1em;
  border-radius: 5px;
  padding: 0.4em 1em;
  border: none;
  color: var(--color-primary);
  background: var(--color-text);
  transition: all 0.3s;
  cursor: pointer;
}

.dropDownFilter {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  align-items: center;
}

.dropDownFilter label {
  margin-bottom: 1em !important;
}

.react-calendar {
  background-color: var(--color-primary);
}

.react-calendar__tile--now {
  background-color: var(--color-primary-variant);
}

.react-calendar__tile--active {
  background: var(--color-primary-variant);
  color: var(--color-text);
}

.react-calendar__tile--now:hover {
  background-color: var(--color-primary-variant);
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  color: var(--color-text);
}

.box-fechaExoneracion .react-date-picker {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 6px;
}

.box-fechaExoneracion .react-date-picker__inputGroup {
  width: 100%;
  background-color: var(--color-primary);
  height: 40px;
}

.box-fechaExoneracion .react-date-picker__inputGroup input {
  padding: 0em 0.3em !important;
  margin-top: 0 !important;
}

.react-daterange-picker__wrapper {
  border-radius: 5px !important;
  border: none !important;
  background: var(--color-primary-variant);
  color: var(--color-text);
  margin-top: 0.5em;
  padding: 0.5em 0.8em;
  font-size: 1em;
}

.react-daterange-picker {
  width: 100%;
}

.modalContent .column .sliderCurrentImage {
  border-radius: 10px;
  width: 100%;
}

.toggledFilters {
  margin-right: 0.5em;
  background: var(--color-text);
  border: none;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}

.toggledFilters i::before {
  color: var(--color-primary);
  font-size: 1.2em;
  margin: 0;
}

.toggledFilters svg {
  fill: var(--color--primary);
  width: 1.5em;
  height: 1.5em;
  margin: 0;
}

.react-daterange-picker__wrapper input,
.react-daterange-picker__wrapper button {
  padding: 0em 0.3em !important;
  margin-top: 0em !important;
  background: transparent !important;
  font-size: 0.8em !important;
  background: var(--color-primary);
}

.dropDownFilter.toggled .togglebled {
  display: none;
}

.toggledFilters i {
  pointer-events: none;
}

.toggledFilters.toggled {
  transform: rotate(180deg);
}

.toggledFilters svg {
  pointer-events: none;
}

.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: black !important;
}

.notFound {
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notFound h2 {
  color: black !important;
  margin-bottom: 0em;
}

.notFound img {
  height: 50%;
}

.notFound p {
  width: 50%;
  margin-bottom: 1em;
}

.notFound a {
  text-decoration: none;
  background: black;
  color: white;
  padding: 0.8em 1em;
  border-radius: 5px;
}

.css-yk16xz-control {
  background: var(--color-primary) !important;
  border: none !important;
  margin-top: 0.5em !important;
}

.css-yk16xz-control .css-1wa3eu0-placeholder {
  color: var(--color-text) !important;
}

.css-yk16xz-control .css-1rhbuit-multiValue {
  background: var(--color-primary-variant) !important;
}

.css-yk16xz-control .css-1rhbuit-multiValue div {
  color: var(--color-text);
}

.css-1pahdxg-control {
  border: none !important;
  box-shadow: none !important;
  background: var(--color--primary) !important;
  margin-top: 0.5em;
}

.clearFormLineaDetalle {
  margin-left: auto !important;
  margin-right: 0em;
  font-size: 0.9em;
  text-decoration: underline;
  display: inline-block;
  background: transparent;
  border: none;
  font-family: inherit;
  color: var(--color-text);
  cursor: pointer;
}

.loadPDF {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@keyframes snippetLoadPDF {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadPDF .snippet {
  width: 7.5em;
  height: 7.5em;
  border: 6px solid #888;
  border-left: 6px solid black;
  border-radius: 50%;
  animation: snippetLoad 1s ease-out;
  animation-iteration-count: infinite;
}

.loadPDF .porcent {
  position: absolute;
  font-size: 1em;
  color: black;
}

.loadPDF + div {
  display: none;
}

.loadPDF.hidden + div {
  display: flex;
}

.container-error {
  background-color: var(--color-primary);
  border-radius: 10px;
  width: 95%;
  padding: 0 10px;
  margin: auto;
}

.errors-denied {
  display: block;
  font-size: 16px;
  width: 88%;
  padding-left: 30px;
  padding-bottom: 10px;
}

.terminosCondiciones {
  height: 100%;
  width: 100%;
  display: block;
  margin: 5em;
  color: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.terminosCondiciones h2 {
  color: black !important;
  margin-bottom: 0em;
}

.terminosCondiciones img {
  height: 50%;
}

.terminosCondiciones p {
  width: 100%;
  margin-bottom: 1em;
}

.terminosCondiciones a {
  text-decoration: none;
  background: black;
  color: white;
  padding: 0.8em 1em;
  border-radius: 5px;
}

@media screen and (max-width: 1024px) {
  .listSection {
    padding: 0em 1em 0em 1em;
  }
  .bodyList ul .itemBodyList {
    overflow-x: scroll;
  }
  .bodyList ul .itembody {
    overflow-x: visible;
  }
  .navSection {
    grid-gap: 0.5rem;
    gap: 0.5rem;
    align-items: end;
    padding: 0.4em 0.4em;
  }
  .dropDownFilter {
    width: 60% !important;
    align-items: end;
    font-size: 14px;
  }
  .dropDownFilter label {
    margin-bottom: 0 !important;
    width: 25% !important;
    padding: 0 4px;
  }
}

@media screen and (max-width: 768px) {
  .navSection {
    flex-wrap: wrap;
    z-index: 800 !important;
  }
  .dropDownFilter {
    width: 82% !important;
  }
  .addContent {
    margin-left: 0;
    width: 100% !important;
    padding: 10px 20px;
  }
  .listSection ul li {
    font-size: 12px;
  }
  .bodyList ul .itemBodyList .contentItemBodyList {
    overflow: visible;
    width: max-content;
  }

  .bodyList ul .itemBodyList .contentItemBodyList li {
    min-width: 45px;
  }
  .bodyList ul .itemBodyList .contentItemBodyList .moneda {
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 576px) {
  .listSection ul li {
    font-size: 10px;
  }
  .navSection .sortBy {
    justify-content: space-between;
    align-items: end;
  }
  .navSection .sortBy label {
    width: 33% !important;
  }

  .bodyList ul .itemBodyList .contentItemBodyList .moneda {
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 468px) {
  .listSection ul li {
    font-size: 9px;
  }

  .bodyList ul .itemBodyList .contentItemBodyList .moneda {
    margin-left: 20px !important;
  }
}

